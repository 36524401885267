.plus_icon {
    // font-size: 40px;
    padding: 21px 21px;
    background-color: var(--primary);
    color: var(--light);
    border-radius: 50%;
    z-index: 10;
    position: relative;
    transition: transform .2s ease;
    opacity: 1;

    &:hover {
        transform: scale(1.1);
    }


    &::before,
    &::after {
        content: "";
        position: absolute;
        width: 33%;
        height: 1px;
        top: calc(50% - 1px);
        left: 50%;
        background: white;
        transform: translate(-50%, 50%);
    }
    &::after {
        transform: translate(-50%, 50%) rotate(90deg);
    }
}