/*
-------------------------------- 
BUTTON COMPONENT
--------------------------------
*/

.scrollbot {
	position: absolute;
	left: 50%;
	bottom: 50px;
	transform: translate(-50%, -50%);
	text-align: center;

	@include breakpoint(sm) {
		bottom: 50px;
	}

	@include breakpoint(md) {
		bottom: -50px;
	}


	// @include breakpoint(md) {
	// 	bottom: -70px;
	// }

	.container {
		width: fit-content;
	}
}

.js-element {
	display: inline-block;

	.libelle {
		cursor: pointer;
		padding: var(--space-md) var(--space-lg);
		text-align: center;
		margin-bottom: var(--space-md);
		color: var(--fixed-white);
		font-weight: bold;
		background: var(--primary);
		font-size: var(--text-sm);
		transition: .15s ease;
		@include breakpoint(md) {
			font-size: var(--text-md);
		}
		&:hover {
			background: var(--primary-hover);
		}
	}
	&.scrollto svg {
		transform: scale(.75);
		@include breakpoint(md) {
			transform: none;
		}
	}
}

.underline svg {
	rotate: 180deg;
}

.underline,
#breadcrumbs a {
	position: relative;
	color: inherit;
	cursor: pointer;

}

.section_scroll_top {
	height: var(--space-xxl);
	margin: var(--space-xl) 0;
	position: relative;
	.scrollback {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
	}

	.underline {
		display: flex;
		justify-content: center;
		padding: 0 0 14px 0;


		svg {
			rotate: none;
		}

	}
	svg {
		transform: scale(.75);
		@include breakpoint(md) {
			transform: none;
		}
	}

	.scrollback .container {
		padding: 0;
	}

	.scrollback .container .scrollup legend {
		font-size: var(--text-sm);
		color: var(--primary);
		cursor: pointer;
		@include breakpoint(md) {
			font-size: var(--text-base-size);
		}
	}
}

.is-desktop {

	.underline,
	#breadcrumbs a {
		&:after {
			content: "";
			position: absolute;
			height: 1px;
			width: 100%;
			bottom: 0;
			left: 0;
			background: var(--secondary_grey);
			transform: scaleX(0);
			transform-origin: right;
			transition: transform 1.2s $easeSmooth;
		}

		&:hover {
			&:after {
				transform: scaleX(1);
				transform-origin: left;
			}
		}

		&.is-white {
			&:after {
				background: var(--fixed-white);
			}
		}
	}
}