/*
-------------------------------- 
FORMS COMPONENT
--------------------------------
*/

// Default
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

input,
input[type="search"],
input[type="text"],
input[type="email"],
textarea {
  /* Remove First */
  // -webkit-appearance: none;
  // -moz-appearance: none;
  // appearance: none;

  /* Then Style */
  border-radius: 0px;
  border: none;
  background: transparent;
  outline: none;
  color: inherit;

  // font-size: var(--text-md);

  &:focus,
  &:active {
    color: inherit;
  }
}

textarea {
  resize: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
  box-shadow: 0 0 0px 1000px #ffffff inset !important;
}

button,
input[type="submit"] {
  cursor: pointer;
  background: none;
}

// Style

.wpcf7 input[type="text"],
.wpcf7 input[type="tel"],
.wpcf7 input[type="email"] {
  border-bottom: 1px solid var(--primary);
}

.wpcf7 .wpcf7-textarea {
  margin-top: var(--space-lg);
  border: 1px solid var(--primary);
  padding: var(--space-sm);
}

.wpcf7 textarea,
.wpcf7 input[type="email"],
.wpcf7 input[type="text"],
.wpcf7 input[type="tel"] {
  width: 100%;
  margin: 5px 0;
  transition: var(--vcp-transition-timer);
  font-size: var(--text-base-size);
  color: var(--fixed-black);
  padding: var(--space-sm) 0;
}

.wpcf7 button {
  margin-top: var(--space-md);
  padding: 16px 30px;
  font-size: var(--text-base-size);
  background: var(--primary);
  color: var(--fixed-white);
  font-weight: bold;
  text-transform: uppercase;
  border: none;
}

.wpcf7::placeholder {
  opacity: 1;
  font-weight: 500;
  padding-bottom: 6px;
  font-size: var(--text-xs);
  color: var(--fixed-black);
}

.wpcf7-form-control-wrap {
  display: inline-block;
  margin-bottom: var(--space-sm);
  width: 100%;
}

.wpcf7-not-valid-tip,
.wpcf7-response-output {
  color: var(--error);
  font-weight: 600;
}

.wpcf7-response-output {
  margin-top: 6px;
}

[data-name="contact-acceptance"] {
  width: fit-content;
  margin-right: 8px;
}

label[for="contact-acceptance"] {
  a {
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
}
