/*
-------------------------------- 
COLORS
--------------------------------
*/
$primary: #3E9AB2;
$primaryhover: darken($primary, 10);

:root {
    --primary: #{$primary};
    --primary-hover: #{$primaryhover};
    --secondary: #103348;

    --fixed-white: #F4F3EA;
    --fixed-white-90: #F4F3EAE6;
    --fixed-black: #1D2429;

    // --light: #FFFFFF;
    // --dark: #000000;

    --error: #FF1B22;
}

[data-theme="dark"] {
    --primary: #000;
    --secondary: #fff;

    --primary_grey: #1f1f1f;
    --secondary_grey: #f7f7f4;

    --support: #505064;
}