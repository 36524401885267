/*
-------------------------------- 
CONTACT FORM 7
--------------------------------
*/
.screen-reader-response {
    display: none;
}

.wpcf7-not-valid-tip {
    font-size: 12px;
}

// Recaptcha 
.grecaptcha-badge {
    visibility: hidden;
}