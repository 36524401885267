/*
-------------------------------- 
HOME PAGE
--------------------------------
*/

.header_home {
    min-height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    text-transform: uppercase;
    color: var(--fixed-white);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    object-fit: cover;

    &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background: var(--fixed-black);
        opacity: .3;
        z-index: -1;
    }

    .head_title {
        text-align: center;
        padding-bottom: var(--space-xxxl);

        .home_title {
            font-family: var(--font-title);
            font-size: var(--text-md);
            font-weight: bold;
            letter-spacing: .4em;
            margin-bottom: var(--space-sm);

            @include breakpoint(md) {
                font-size: var(--text-xxxl);
                margin-bottom: var(--space-md);
            }
        }

        .home_subtitle_1 h2 {
            font-style: italic;
            font-weight: 500;
            margin-bottom: var(--space-md);
            font-size: var(--text-base-size);

            @include breakpoint(md) {
                font-size: var(--text-lg);
            }
        }

        .home_subtitle_2 h3 {
            font-weight: bold;
            font-size: var(--text-xs);

            @include breakpoint(md) {
                font-size: var(--text-md);
            }
        }

        .trait {
            border: solid var(--fixed-white);
            border-width: 1px 0 0;
            width: 164px;
            text-align: center;
            margin: auto;
            margin-top: 2px;

            @include breakpoint(md) {
                border-width: 1px;
                margin-top: var(--space-sm);
            }
        }
    }
}

.nos_offres_home {

    position: relative;

    .titles_offers {
        margin-top: var(--space-xl);
        text-align: center;

        h2 {
            display: inline-block;
            font-family: var(--font-title);
            font-size: var(--text-base-size);
            font-weight: bold;
            color: var(--fixed-black);
            text-transform: uppercase;
            border-bottom: 1px solid var(--primary);
            padding: 0 0 4px;

            @include breakpoint(md) {
                font-size: var(--text-lg);
                padding: 0 3px 6px;
                border-bottom: 2px solid var(--primary);
            }
        }

        .scnd_trait {
            border: 1px solid var(--primary);
            width: 120px;
            margin: auto;
            margin-top: var(--space-xs);
        }

        .scnd_title_offre h3 {
            margin-top: var(--space-md);
            font-weight: 500;
            color: var(--primary);
            text-transform: uppercase;
            font-size: var(--text-sm);

            @include breakpoint(md) {
                text-transform: none;
                font-size: var(--text-lg);
                color: var(--fixed-black);
            }
        }

        .scnd_title_offre h3::first-letter {
            text-transform: capitalize;
            font-family: var(--font-content);
        }
    }

    .offres_home {
        display: grid;
        justify-content: space-around;
        grid-template-columns: repeat(1, 1fr);
        gap: var(--space-xl);
        padding: 0 var(--space-xl);
        margin: var(--space-lg) 0 var(--space-xl);

        @include breakpoint(md) {
            margin: var(--space-xxl) 0 var(--space-xxl) 0;
            padding: 0 var(--space-xxl);
            gap: var(--space-xxl);
            grid-template-columns: repeat(3, 1fr);
        }
    }

    .show_offers {
        text-align: center;
        padding: var(--space-sm) var(--space-md);
        margin: auto;
        margin-bottom: var(--space-xl);
        background: var(--primary);
        width: fit-content;
        display: block;
        color: var(--fixed-white);
        font-weight: 500;
        text-transform: uppercase;
        transition: background-color .15s ease;

        &:hover {
            background: var(--primary-hover);
        }
    }

}

.skills_home {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: var(--space-xl);

    @include breakpoint(md) {
        display: flex;
        justify-content: center;
        padding: var(--space-xxxl) 0;
        margin-top: var(--space-xxl);
    }

    .content_partner {
        color: var(--fixed-black);
        background: rgba(244, 243, 234, 0.9);
        padding: var(--space-lg) var(--space-md);

        @include breakpoint(md) {
            width: 700px;
            padding: var(--space-xl) var(--space-xxl);
            margin: 0 var(--space-xxxxl);
        }

        .points_home {
            margin: var(--space-lg) 0 var(--space-md);
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: var(--space-xs);

            @include breakpoint(md) {
                gap: 10px;
                align-items: start;
            }

            .point_home {
                margin-bottom: var(--space-sm);
                display: flex;
                align-items: center;
                flex-direction: column;
                position: relative;
                width: 100%;

                @include breakpoint(md) {
                    align-items: start;
                    margin-bottom: var(--space-sm);
                }

                .content_points_home {
                    max-height: 0;
                    overflow: hidden;
                    transition: all .4s;

                }

                .active+.content_points_home {
                    visibility: visible;
                }
            }
        }

        .titles_partner {
            text-align: left;

            h2 {
                font-size: var(--text-base-size);
                font-weight: 600;
                text-align: center;

                @include breakpoint(md) {
                    font-size: var(--text-lg);
                    text-align: left;
                }
            }

            h3 {
                font-size: var(--text-sm);
                font-weight: 500;
                text-align: center;

                @include breakpoint(md) {
                    font-size: var(--text-lg);
                    text-align: left;
                }
            }
        }

        .trait_skills {
            border: solid var(--primary);
            border-width: 1px 0 0;
            width: 100%;
            margin: auto;
            margin-top: 5px;
            display: block;

            @include breakpoint(md) {
                border-width: 1px;
                margin-top: var(--space-sm);
            }
        }

        .text_partner {
            font-weight: 300;
            margin: var(--space-md) 0;
            font-size: var(--text-sm);

            @include breakpoint(md) {
                padding-right: var(--space-lg);
                font-size: var(--text-base-size);
            }
        }



        .collapsible.active {
            margin-bottom: 15px;
        }

        .collapsible {
            width: 100%;
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: var(--space-sm);

            @include breakpoint(md) {
                display: grid;
                grid-template-columns: fit-content(40%) 1fr fit-content(20%);
                flex-direction: row;
            }



            .plus {
                display: flex;
                align-items: center;
                justify-content: center;
                float: left;
                position: relative;
                width: fit-content;
                padding: var(--space-xs) var(--space-sm);
                border: solid var(--primary);
                border-width: 1px;
                text-transform: uppercase;
                font-weight: 500;
                font-size: var(--text-sm);

                @include breakpoint(md) {
                    border-width: 2px;
                }
            }

            .trait_point {
                height: 25px;
                width: 2px;
                // display: flex;
                background-color: var(--primary);
                align-self: center;

                @include breakpoint (md) {
                    height: 2px;
                    width: 100%;
                    // display: flex;
                    background-color: var(--primary);
                    align-self: center;
                }
            }

            .plus_icon {
                padding: 15px 15px;
                width: fit-content;
                margin: auto;
            }
        }

        .active {
            width: 100%;
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;

            @include breakpoint(md) {
                display: grid;
                grid-template-columns: fit-content(40%) 1fr fit-content(20%);
                flex-direction: row;
            }

            .plus {
                float: left;
                position: relative;
                width: fit-content;
                border-color: var(--fixed-black);
            }

            .trait_point {
                background-color: var(--fixed-black);
            }

            .plus_icon {
                padding: 15px 15px;
                background-color: var(--secondary);

                &::before {
                    width: 13px;
                }

                &::after {
                    display: none;
                    height: 0;
                    width: 0;
                    visibility: hidden;
                }
            }
        }

        .content_points_home {
            text-align: left;
            font-size: var(--text-sm);

            @include breakpoint(md) {
                font-size: var(--text-base-size);
            }
        }
    }

}

@include breakpoint(md) {

    .header_home {

        .head_title {
            text-align: center;
            padding-bottom: var(--space-xxxl);

            .home_title {
                font-family: var(--font-title);
                // font-size: 4rem;
                font-weight: bold;
                letter-spacing: .35em;
                margin-bottom: 24px;
            }

            .home_subtitle_1 h2 {
                font-style: italic;
                font-weight: 500;
                margin-bottom: 24px;
            }

            .home_subtitle_2 h3 {
                font-weight: 700;
            }

            .trait {
                border: 1px solid var(--fixed-white);
                width: 162px;
                text-align: center;
                margin: auto;
                margin-top: var(--space-sm);
            }
        }
    }

    .nos_offres_home {

        position: relative;

        .titles_offers {
            margin-top: var(--space-xl);
            text-align: center;

            h2 {
                font-family: var(--font-title);
                font-weight: bold;
                color: var(--fixed-black);
                text-transform: uppercase;
            }

            .scnd_trait {
                border: 1px solid var(--primary);
                width: 160px;
                margin: auto;
                margin-top: var(--space-xs);
            }

            .scnd_title_offre h3 {
                margin-top: var(--space-md);
                text-transform: lowercase;
                font-weight: 500;
            }

            .scnd_title_offre h3::first-letter {
                text-transform: capitalize;
                font-family: var(--font-content);
            }
        }

        .show_offers {
            width: fit-content;
            margin: auto;
            padding: var(--space-md) var(--space-lg);
            margin-bottom: var(--space-xl);
            background: var(--primary);
            color: var(--fixed-white);
            font-weight: bold;
            text-transform: uppercase;


        }

    }
}