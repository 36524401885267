@include breakpoint(md) {
    .page-id-143 .app>.logo_container {
        display: none;
    }
}

.conseil_estimation {
    min-height: 100vh;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;

    .left_bloc,
    .right_bloc {
        width: 100%;
        min-height: 70vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        box-sizing: border-box;
        padding: var(--space-xxl) var(--space-xxxl);


        @include breakpoint(md) {
            min-height: 100vh;
            flex-basis: 50%;
        }

        .title_bloc_conseil {
            background: rgba(29, 36, 41, 0.7);
            padding: var(--space-md) var(--space-lg);

            @include breakpoint(md) {
                background: rgba(29, 36, 41, 0.9);
                margin: auto 0;
                padding: var(--space-lg) var(--space-lg);
                padding: var(--space-xxl) var(--space-xl);
            }

            .title_conseil_estimation {
                font-size: var(--text-md);
                text-transform: uppercase;
                text-align: center;
                font-weight: 600;

                @include breakpoint(md) {
                    text-align: left;
                    font-size: var(--text-xl);
                    padding-bottom: var(--space-xs);
                }
            }
        }

        .conseil_border_bot {
            border: 1px solid var(--primary);
            width: 100%;
            margin: auto;
            margin-top: var(--space-xs);
            display: block;
            position: relative;
            float: none;

            @include breakpoint(md) {
                float: left;
            }
        }
    }

    .left_bloc {
        // background: grey;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        object-fit: contain;
        color: var(--fixed-white);
        text-transform: uppercase;
        position: relative;
        justify-content: space-between;
        padding: var(--space-xxl) var(--space-lg);

        @include breakpoint(md) {
            padding: var(--space-lg) var(--space-xxxl) var(--space-xxl);
        }

        * {
            position: relative;
        }

        &::before {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            background: var(--fixed-black);
            opacity: .3;
            z-index: 0;
            inset: 0;

        }

        @include breakpoint(md) {
            position: sticky;
            top: 0;
        }

        .logo_container {
            display: none;

            @include breakpoint(md) {
                display: block;
                padding: 0;
                margin: 0 0 var(--space-lg);
            }
        }

        .link_left_bloc {
            cursor: pointer;
            text-align: center;
            font-size: 11px;

            @include breakpoint(md) {
                align-self: start;
                font-size: var(--text-sm);
            }

            .link_conseil {
                text-decoration: underline;
            }
        }

        .invisible {
            opacity: 0;
            visibility: hidden;
        }
    }

    .right_bloc {
        padding: var(--space-lg);

        @include breakpoint(md) {
            padding: var(--space-xl) var(--space-xxl) var(--space-lg) var(--space-xl);
        }

        .container_conseil_step {
            height: 100%;

            .step_header {
                @include breakpoint(md) {
                    width: fit-content;
                    max-width: 65%;
                    margin-right: var(--space-xxl);
                }

                .title_header_steps {
                    font-family: var(--font-title);
                    font-size: var(--text-md);
                    font-weight: bold;
                    text-transform: uppercase;

                    @include breakpoint(md) {
                        font-size: var(--text-lg);
                    }
                }
            }

            .step_header+p {
                font-size: .85rem;
                text-align: left;
                padding: var(--space-md) 0;

                @include breakpoint(md) {
                    font-size: 1.125rem;
                }
            }
        }

        .steps {

            .step_title {
                font-size: var(--text-base-size);
                font-weight: 500;

                @include breakpoint(sm) {
                    font-size: var(--text-md);
                }
            }

            .step_1,
            .step_2,
            .step_3 {
                display: flex;
                margin-top: var(--space-md);

                .sigle_step {
                    height: 30px;
                    width: 30px;
                    margin: var(--space-xs) var(--space-sm) 0 0;

                    @include breakpoint(sm) {

                        height: 40px;
                        width: 40px;
                    }

                    @include breakpoint(md) {
                        margin: 0 var(--space-lg) 0 0;
                        height: 57px;
                        width: 57px;
                    }
                }

                .step_contenu {
                    h4 {
                        font-weight: 500;
                        margin-top: var(--space-xs);
                        font-size: var(--text-base-size);

                        @include breakpoint(md) {
                            font-size: var(--text-lg);
                        }
                    }

                    p {
                        margin-top: var(--space-sm);
                        font-weight: 300;
                        font-size: var(--text-sm);

                        @include breakpoint(md) {
                            font-weight: 400;
                            font-size: var(--text-base-size);
                        }
                    }
                }
            }

            .step_1 {

                @include breakpoint(sm) {
                    margin-top: var(--space-lg);
                }
            }

            .contact_step {
                margin-top: var(--space-xl);
                text-align: center;

                @include breakpoint(md) {
                    margin: var(--space-xl) 0 var(--space-md);
                    display: flex;
                    flex-wrap: wrap;
                    align-items: flex-end;
                    justify-content: flex-end;
                    text-align: right;
                    padding: var(--space-sm) 0;
                }

                p {
                    font-style: italic;
                    font-weight: 500;
                    font-size: .85rem;

                    @include breakpoint(md) {
                        margin-right: var(--space-lg);
                        font-size: var(--text-sm);
                    }
                }

                .btn_contact_us {
                    display: inline-block;
                    margin: var(--space-md) 0;
                    padding: var(--space-md) var(--space-lg);
                    background: var(--primary);
                    color: var(--fixed-white);
                    text-transform: uppercase;
                    font-weight: 800;
                    text-align: center;

                    @include breakpoint(md) {
                        padding: var(--space-sm) var(--space-md);
                        justify-content: space-between;
                        margin: 0;
                    }
                }
            }
        }
    }
}