.card-article > a:hover {
  .zoomEffect .img-landscape img {
    transform: scale(1.1);
  }
  .article-content .description .container_glob .plus_icon {
    transform: translateY(calc(-1 * (var(--space-md) + 50%))) scale(1.1);
  }
}

.article-content {
  position: relative;
  padding-bottom: 160%;
  position: relative;

  .zoomEffect {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    .img-landscape {
      img {
        transition: transform 0.2s ease;
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
  }

  .location {
    background: var(--primary);
    color: var(--fixed-white);
    position: absolute;
    left: -14px;
    top: 50px;
    font-family: var(--font-title);
    font-weight: 400;
    font-size: var(--text-base-size);
    text-transform: uppercase;
    padding: 6px 10px;
    padding-top: 12px;
    @include breakpoint(md) {
      font-size: var(--text-md);
      padding: 8px 10px;
    }
  }

  .description {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: var(--space-sm) var(--space-md);
    color: var(--fixed-white);
    background-color: rgba(29, 36, 41, 0.9);
    font-size: var(--text-sm);
    @include breakpoint(md) {
      padding: var(--space-md) var(--space-lg);
      font-size: var(--text-base-size);
    }
    .container_glob {
      position: relative;

      .plus_icon {
        position: absolute;
        transform: translateY(calc(-1 * (var(--space-md) + 50%)));
        right: 0;
      }

      .description_bien {
        line-height: 1;
        position: relative;

        h4 {
          font-size: var(--text-base-size);
          font-weight: 600;
          margin-bottom: 10px;
          &::first-letter {
            text-transform: uppercase;
          }
        }

        span {
          font-size: 0.875rem;
          img {
            height: 100%;
          }
        }
      }

      .chiffres {
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
        text-transform: lowercase;
        // font-size: 20px;

        legend,
        span {
          text-decoration: underline;
          text-underline-offset: 0.35em;
        }
      }
    }

    .description::first-letter {
      text-transform: uppercase;
    }
  }
}

@include breakpoint(md) {
  .card-article {
    padding: 0 var(--space-xs);
  }
}
