/*
-------------------------------- 
DEFAULT COMPONENT
--------------------------------
*/

// Ajax load more
.loading-container {
    display: none;
}

// Filters Categories
.filters-container {
    display: flex;

    .item-filter {
        &:not(:last-child) {
            margin-right: var(--space-sm);
        }

        &.active {
            opacity: 0.64;
            pointer-events: none;
        }
    }
}

// Progress line
#progress-line {
    position: fixed;
    top: 0;
    left: 0;
    width: 0;
    background: var(--brand_primary);
    height: 2px;
    z-index: $page_up_level;
}

// Images Container
.img-landscape,
.img-portrait {
    display: block;
    height: 100%;

    div {
        height: auto;

        img {
            object-fit: cover;
            width: 100%;
            height: 100%;
            position: absolute;
            outline: 1px solid transparent;
            display: block;
            top: 0;
            left: 0;
        }
    }
}

// Images Portrait
// .img-landscape {
//     padding-bottom: 56.25%;
// }

// Images landscape
.img-portrait {
    padding-bottom: 150%;
}

// Video container
.video-container {
    padding-bottom: 56.25%;
    height: 0;
    background: var(--secondary);

    video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

// Block Post Content
.content {

    h2,
    h3,
    h4 {
        margin-top: var(--space-sm);
    }

    ul,
    ol,
    p {
        margin-bottom: var(--space-md);
    }

    ul,
    ol {
        list-style-position: outside;
        padding-left: var(--space-md);
    }

    ul {
        list-style-type: disc;

        li {
            list-style: initial;
        }
    }

    ol {
        list-style-type: decimal;
    }

    ul li,
    ol li {
        line-height: var(--body-line-height);
    }

    em {
        font-style: italic;
    }

    u {
        text-decoration: underline;
    }

    a {
        position: relative;
        color: var(--secondary_grey);

        &:not(.no-link) {
            &:after {
                content: "";
                position: absolute;
                height: 1px;
                width: 100%;
                background: var(--secondary_grey);
                bottom: 0;
                left: 0;
                transform: scaleX(1);
                transform-origin: left;
                transition: transform 1.2s $easeSmooth;
            }

            &:hover {
                &:after {
                    transform: scaleX(0);
                    transform-origin: right;
                }
            }
        }
    }
}

/* utility classes */
.truncate {
    // truncate text if it exceeds parent
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}