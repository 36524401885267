/*
-------------------------------- 
THEME GLOBAL
--------------------------------
*/
@import "base/fonts";
@import "base/default";

// Layout
@import "layout/header";
@import "layout/footer";
@import "layout/partial";

// Pages
@import "pages/home";
@import "pages/single_offre";
@import "pages/about";
@import "pages/contact";
@import "pages/equipe";
@import "pages/conseil_et_estimations";
@import "pages/layout_nos_services";
@import "pages/gestion_locative";
@import "pages/offres";
@import "pages/actus";
@import "pages/404";
@import "pages/pdf";
@import "pages/single_actus";
@import "pages/mentions_legales";

// Components
@import "components/partial";
@import "components/buttons";
@import "components/preloader";
@import "components/form";
@import "components/cookies";
@import "components/lazyload";
@import "components/hero";
@import "components/scroll";
@import "components/offres_component";
@import "components/plus";
@import "components/pagination";
@import "components/actus_component";

// Delete this
@import "replace";