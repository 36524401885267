body.error404 {
    background: var(--fixed-black);
    color: var(--fixed-white);
}
.app {
    height: 100%;
    #page-404 {
        height: 100%;
        .section-content {
            height: 100%;
            padding-top: 9.5rem;
            padding-bottom: 3.75rem;
            .container {
                height: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                text-align: center;
                .frame {
                    border: 2px solid var(--primary);
                    padding: 7rem 5rem;
                    margin: auto 0;
                    > div {
                        font-size: 43px;
                        font-weight: 600;
                    }
                }
                .underline {
                    margin-bottom: 2rem;
                }
                h1 {
                    font-size: 90px;
                    font-family: var(--font-title);
                    font-weight: bold;
                    text-transform: uppercase;
                }
                .btn {
                    font-size: var(--text-md);
                    padding: var(--space-md) var(--space-lg);
                    color: var(--fixed-white);
                    background: var(--primary);
                    font-weight: bold;
                    text-transform: uppercase;
                }
            }
        }
    }
}
