/*
-------------------------------- 
PARTIAL LAYOUT
--------------------------------
*/
.current-menu-item {
    pointer-events: none;

    a {
        opacity: 0.64;
    }
}

.page-wrapper {
    min-height: calc(100vh - (100px + var(--space-md) * 2));
}