.equipe {
  a {
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
  .header_equipe {
    .header_fil {
      padding: var(--space-xxl) var(--space-lg) var(--space-xl) var(--space-lg);

      @include breakpoint(md) {
        padding: var(--space-xxl) var(--space-xxxl) var(--space-xl) var(--space-xxxl);
      }

      .fil_ariane_equipe {
        padding-top: var(--space-md);
        font-size: 11px;
        cursor: pointer;
        text-align: center;
        color: var(--primary);
        text-transform: uppercase;

        @include breakpoint(md) {
          text-align: left;
          font-size: 0.875em;
        }

        span {
          font-weight: 500;
        }

        .link_conseil {
          text-decoration: underline;
          font-weight: 500;
        }
      }

      .big_title_equipe {
        font-size: 1.25em;
        font-weight: 600;
        margin: 17px 0;

        @include breakpoint(md) {
          font-size: 2.5em;
          margin: 30px 0 17px;
        }
      }
    }

    .presentation_equipe {
      width: 100%;
      padding: var(--space-md) var(--space-lg);
      margin: auto;
      background-color: rgba(0, 0, 0, 0.9);
      position: relative;
      z-index: 0;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      object-fit: contain;
      overflow: hidden;

      @include breakpoint(md) {
        padding: var(--space-md) var(--space-xxxl);
      }

      &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background: var(--fixed-black);
        opacity: 0.9;
        z-index: -1;
        inset: 0;
      }

      .header_title {
        font-family: var(--font-title);
        font-size: var(--text-md);
        font-weight: bold;
        text-decoration: underline var(--primary);
        text-underline-offset: 0.4em;
        text-decoration-thickness: 1px;
        color: var(--fixed-white);
        text-transform: uppercase;
        padding: var(--space-md) 0;
        text-align: center;

        @include breakpoint(md) {
          font-size: var(--text-lg);
          text-decoration-thickness: 2px;
          text-align: left;
          padding: var(--space-md);
        }
      }

      .custom_swiper_wrapper {
        padding: var(--space-sm) var(--space-lg);

        @include breakpoint(md) {
          padding: var(--space-lg);
        }

        .swiper {
          height: 300px;
          overflow: hidden;

          .swiper-button-disabled {
            display: none;
          }

          .swiper-button-prev,
          .swiper-button-next {
            color: #f4f3ea;
            margin-top: 0;
          }

          .swiper-wrapper {
            display: flex;
            align-items: center;

            .swiper-slide {
              position: relative;
              height: 256px;
              width: 200px;
              overflow: hidden;
              cursor: pointer;

              @include breakpoint(md) {
                &:hover img {
                  transform: scale(1.1);
                }
              }

              @include breakpoint(xl) {
                height: 306px;
                width: 250px;
              }

              img {
                transition: 0.25s ease;
                height: 100%;
                width: 100%;
                object-fit: cover;
              }

              .infos_perso {
                background-color: var(--primary);
                opacity: 0.9;
                position: absolute;
                bottom: 0;
                text-align: left;
                padding: var(--space-xs) var(--space-sm);
                color: var(--fixed-white);
                width: 100%;
                display: none;

                @include breakpoint(md) {
                  width: auto;
                }

                & > p {
                  font-size: var(--text-md);
                  font-weight: 600;

                  @include breakpoint(xl) {
                    font-size: var(--text-sm);
                  }
                }

                & > span {
                  display: block;
                  font-size: var(--text-sm);
                  font-weight: 500;

                  @include breakpoint(xl) {
                    font-size: var(--text-xs);
                  }
                }
              }
            }

            .swiper-slide-thumb-active {
              .infos_perso {
                display: block;
              }
            }
          }
        }
      }
    }

    .parcours_equipe {
      padding: var(--space-xl) var(--space-lg);

      @include breakpoint(md) {
        padding: var(--space-xxxl) var(--space-xxxl) var(--space-xl);
      }

      .swiper2 {
        overflow: hidden;
        height: auto;

        .parcours_item {
          display: flex;
          flex-direction: column;

          @include breakpoint(md) {
            flex-direction: row;
            align-items: center;
          }

          .infos_perso {
            border: 1px solid var(--primary);
            padding: var(--space-md) var(--space-md) 250px;
            position: relative;
            display: flex;
            font-size: var(--text-sm);

            @include breakpoint(md) {
              flex: 0 0 50%;
              padding: var(--space-xl) var(--space-xxl);
              font-size: var(--text-base-size);
              border-width: 2px;
            }

            .description {
              & > .name_equipier {
                font-size: var(--text-lg);
                font-weight: 600;
              }

              & > .job_equipier {
                color: var(--primary);
              }

              & > .parcours_description {
                margin-top: var(--space-lg);
              }
            }
          }

          .imgParcours {
            position: relative;
            height: 313px;
            margin-left: var(--space-md);
            margin-top: -220px;
            width: calc(100% - 2 * var(--space-md));

            @include breakpoint(md) {
              // width: 40%;
              height: 0;
              margin-left: -50px;
              margin-top: 0;
              padding: 0;
              padding-top: 26.5%;
              width: 100%;
            }

            & > img {
              position: absolute;
              inset: 0;
              height: 100%;
              width: 100%;
              object-fit: cover;
            }
          }
        }
      }
    }
  }
}
