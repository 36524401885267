#single-article {
    padding: var(--space-xl) var(--space-lg) 0;

    @include breakpoint(md) {
        padding: var(--space-xxl) var(--space-xxxl) 0 var(--space-xxxl);
    }

    position: relative;

    .header-section {
        padding-top: var(--space-md);

        .breadcrumbs {
            text-transform: uppercase;
            font-size: 0.6875em;
            font-weight: 500;
            color: var(--primary);
            text-align: center;
            position: relative;
            z-index: 10;

            @include breakpoint(md) {
                text-align: start;
                font-size: .875em;
                color: #B1B0AA;
            }

            a {
                font-weight: 500;
            }

            .link-actu {
                color: var(--primary);
                font-weight: 500;
                text-decoration: underline;
                text-underline-offset: .2em;

                @include breakpoint(md) {
                    color: var(--fixed-black);
                }
            }
        }
    }

    .content-article {
        .title {
            font-size: 1.25em;
            font-weight: 600;
            color: #103348;
            margin: 17px 0;

            @include breakpoint(md) {
                font-size: 2.5em;
                margin: 30px 0 17px;
            }
        }

        .introduction {
            .introduction-img {
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }

        .content {
            padding: var(--space-md) 0 var(--space-xxl);

            @include breakpoint(md) {
                padding: var(--space-xl) var(--space-xxxl) var(--space-xxxl);
            }

            .accroche {
                p {
                    font-family: var(--font-title);
                    font-weight: 700;
                    line-height: 26px;

                    @include breakpoint(md) {
                        font-size: 1.875em;
                        line-height: 46px;
                    }
                }
            }

            .main-content {
                p {
                    font-size: 11px;

                    @include breakpoint(md) {
                        font-size: 1.125em;
                    }

                    font-weight: 300;
                    object-fit: cover;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }

            .publication-infos,
            span {
                font-weight: 300;
                font-size: 11px;

                @include breakpoint(md) {
                    font-size: 1.125em;
                }
            }
        }
    }

    .actus {
        .more-post {
            overflow: hidden;

            @include breakpoint(md) {
                overflow: none;
            }

            .title {
                font-size: 1.25em;
                font-weight: 600;
                color: #103348;
                text-transform: uppercase;
                margin-bottom: 19px;

                @include breakpoint(md) {
                    font-size: 2.68em;
                }
            }

            .cards-article.swiper-wrapper {
                display: flex;
                gap: 0;

                @include breakpoint(md) {
                    display: grid;
                }

                .card-news {
                    margin-bottom: 0;
                }
            }
        }
    }

    .social-media {
        position: absolute;
        left: 0;
        top: 50%;
        height: 104px;
        width: 28px;
        background-color: var(--primary);
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;

        @include breakpoint(md) {
            height: 180px;
            width: 60px;
        }

        .fb,
        .google,
        .linkedin {
            img {
                height: 13px;
                width: 13px;

                @include breakpoint(md) {
                    height: 28px;
                    width: 28px;
                }
            }
        }
    }
}