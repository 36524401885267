#page-mentions-legales {

    padding: var(--space-xl) var(--space-lg) 0;

    @include breakpoint(md) {
        padding: var(--space-xxl) var(--space-xxxl) 0 var(--space-xxxl);
    }

    .container {
        .breadcrumb {
            padding-top: var(--space-lg);
            text-align: center;
            font-size: 0.6875em;
            font-weight: 500;
            text-transform: uppercase;

            @include breakpoint(md) {
                font-size: 0.875em;
                text-align: left;
            }

            .accueil {
                color: #B1B0AA;
            }

            .current-page {
                color: var(-fixed-black);
                text-decoration: underline;
            }
        }

        main {
            .big_title {
                font-size: 1.25em;
                font-weight: 600;
                color: var(--color-big-title);
                margin: 17px 0;

                @include breakpoint(md) {
                    font-size: 2.68em;
                    margin: 66px 0 38px;
                }
            }

            .content:not(:last-child) {
                margin-bottom: var(--space-xl);


            }

            .content {
                .title {
                    color: var(--fixed-black);
                    font-weight: 600;
                    font-size: var(--text-base-size);

                    @include breakpoint(md) {
                        font-size: 1.875em;
                    }
                }

                .informations {
                    margin: 17px 0 0;
                    font-size: 0.6875em;
                    font-weight: 600;
                    color: var(--fixed-black);

                    @include breakpoint(md) {
                        font-size: 1.125em;
                    }
                }

            }
        }
    }
}