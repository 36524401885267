.page-news {
    padding: var(--space-xxl) var(--space-lg) var(--space-xxl);

    @include breakpoint(md) {
        padding: var(--space-xxl) var(--space-xxxl) var(--space-xxl);
    }

    .breadcrumbs {
        text-align: center;
        text-transform: uppercase;
        font-size: 11px;
        font-weight: 500;
        color: var(--primary);

        a,
        span {
            font-weight: 500;
            text-decoration: underline;

            @include breakpoint(md) {
                color: var(--fixed-black);
            }
        }

        @include breakpoint(md) {
            color: #00000046;
            text-align: left;
            font-size: .875rem;
            padding-top: var(--space-md);
        }
    }

    .firstArticle,
    .allArticles {
        margin-top: var(--space-lg);

        .titleArticle,
        .titleArticles {
            text-align: center;
            font-size: var(--text-md);
            font-weight: 600;
            color: var(--secondary);

            @include breakpoint(md) {
                text-align: left;
                font-size: var(--text-xl);
            }
        }
    }
}

.actus {
    .cards-article {
        margin: var(--space-lg) 0;

        @include breakpoint(md) {
            margin: var(--space-lg) 0 var(--space-xl);
            padding: 0;
        }

        .card-news-first {
            position: relative;

            @include breakpoint(md) {
                grid-column: 1 / 4;
                grid-row: 1;
            }

            .item_news {
                height: 100%;

                .actu-content {
                    position: relative;
                    display: flex;

                    @include breakpoint(md) {
                        margin-bottom: 0;
                        flex-direction: row;
                        align-items: center;
                    }

                    .zoomEffect {
                        padding-top: 70%;
                        position: relative;
                        overflow: hidden;

                        @include breakpoint(md) {
                            flex: 0 0 50%;
                            padding-top: 40%;
                        }

                        img {
                            position: absolute;
                            inset: 0;
                            object-fit: cover;
                            width: 100%;
                            height: 100%;
                            transition: .25s ease;
                        }
                    }

                    .description {
                        padding: var(--space-sm) 0;
                        color: var(--fixed-white);

                        @include breakpoint(md) {
                            position: relative;
                            padding: var(--space-md) var(--space-md);
                        }

                        .plus_icon {
                            display: none;
                        }

                        .container {
                            position: relative;
                            padding: 0;

                            @include breakpoint(md) {
                                padding: 0 0 0 var(--space-xl);
                            }

                            .category_item {
                                font-size: var(--text-sm);
                                font-weight: 700;
                                color: var(--secondary);
                                pointer-events: auto;

                                @include breakpoint(md) {
                                    font-size: var(--text-md);
                                }
                            }

                            .description_actu {
                                margin-bottom: var(--space-lg);
                                pointer-events: auto;

                                .titre {
                                    font-size: var(--text-md);
                                    font-weight: 500;
                                    color: var(--secondary);
                                    margin-top: var(--space-xxs);
                                    line-height: 1.2;

                                    @include breakpoint(md) {
                                        font-size: var(--text-xl);
                                        margin-top: var(--space-xs);
                                    }
                                }
                            }

                            .infos {
                                display: flex;
                                color: var(--fixed-black);
                                font-weight: 200;
                                font-size: var(--text-sm);
                                margin-bottom: var(--space-sm);

                                @include breakpoint(md) {
                                    font-size: var(--text-base-size);
                                    margin-bottom: var(--space-lg);
                                }

                                legend {
                                    display: inline-block;
                                    padding-left: .4em;
                                }
                            }

                            button {
                                font-size: var(--text-sm);
                                background-color: var(--primary);
                                color: var(--fixed-white);
                                font-weight: 700;
                                padding: var(--space-sm) var(--space-md);
                                pointer-events: auto;

                                @include breakpoint(md) {
                                    font-size: var(--text-md);
                                }
                            }
                        }
                    }
                }

                &:hover .zoomEffect img {
                    transform: scale(1.1);
                }
            }
        }

        .card-news {
            .item_news {
                .actu-content {
                    position: relative;
                    padding-bottom: 106%;

                    .zoomEffect {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        overflow: hidden;
                    }

                    .img_article {
                        img {
                            transition: transform .2s ease;
                            height: 100%;
                            width: 100%;
                            object-fit: cover;
                        }
                    }

                    .description {
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        width: 100%;
                        padding: var(--space-sm);
                        background-color: rgba(244, 243, 234, .9);
                        font-size: var(--text-sm);

                        @include breakpoint(md) {
                            padding: var(--space-md);
                            font-size: var(--text-base-size);
                        }

                        .plus_icon {
                            display: none;
                            position: absolute;
                            transform: translateY(calc(-1 * (var(--space-sm) + 50%)));
                            right: var(--space-sm);

                            @include breakpoint(md) {
                                display: block;
                                right: var(--space-md);
                                transform: translateY(calc(-1 * (var(--space-md) + 50%)));
                            }
                        }

                        .category_item {
                            color: var(--secondary);
                        }

                        button {
                            font-size: var(--text-sm);
                            background-color: var(--primary);
                            color: var(--fixed-white);
                            font-weight: 700;
                            padding: var(--space-sm) var(--space-md);
                            margin-top: var(--space-sm);

                            @include breakpoint(md) {
                                display: none;
                                visibility: hidden;
                                font-size: var(--text-md);
                            }
                        }

                        .container {
                            position: relative;

                            .category_item {
                                font-size: var(--text-md);
                                font-weight: 700;
                            }

                            .description_actu {
                                margin-bottom: var(--space-lg);

                                .titre {
                                    font-weight: 500;
                                    font-size: var(--text-md);
                                    line-height: 1.2;
                                    margin: var(--space-xxs) 0 var(--space-md);
                                }
                            }

                            .infos {
                                display: flex;
                                font-weight: 200;
                                font-size: var(--text-sm);

                                legend {
                                    display: inline-block;
                                    padding-left: .4em;
                                }
                            }
                        }
                    }
                }

                &:hover .zoomEffect img {
                    transform: scale(1.1);
                }
            }
        }
    }
}