/*
-------------------------------- 
FOOTER LAYOUT
--------------------------------
*/
footer {
    padding: var(--space-xl) var(--space-lg) var(--space-xxl) ;
    background-color: rgba(29, 36, 41, 0.9);
    position: relative;
    z-index: 10;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    object-fit: contain;
    &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background: var(--fixed-black);
        opacity: .9;
        z-index: -1;
        inset: 0;
    }
    @include breakpoint(xl) {
        padding: var(--space-xl) var(--space-xxl);
    }

    .container {
        overflow: hidden;
    }

    .footer-container {
        display: inline;
        color: var(--fixed-white);
        text-align: center;

        .horizontal_line {
            width: 70%;
            height: 2px;
            background-color: var(--primary);
            margin: var(--space-md) auto;
        }

        .app-copyright {
            font-family: var(--font-title);
            font-size: var(--text-md);
            font-weight: bold;
            text-transform: uppercase;
            letter-spacing: .4em;
        }

        .head_footer {
            width: 100%;
        }

        #nav-footer {
            padding-top: var(--space-md);
            font-size: var(--text-sm);
            @include breakpoint(md) {
                padding-top: var(--space-xl);
                font-size: var(--text-base-size);
            }
            a:hover {
                text-decoration: underline;
            }
        }

        .nav_footer {
            flex-wrap: wrap;
            justify-content: space-between;

            .page_link {
                &>.header_transition {

                    &>ul>.menu-item-has-children {
                        margin-bottom: var(--space-md);
                        font-weight: 500;
                    }

                    &>.dropdown_item {
                        @include breakpoint(md) {
                            display: flex;
                            width: 100%;
                            gap: var(--space-xxl);
                        }

                    }
                }

                ul:first-child>li:last-child {
                    font-weight: 500;
                }
            }

            .contact_footer {
                font-weight: 500;
                position: relative;
                .telephone {
                    margin: var(--space-md) 0;
                }

                @include breakpoint(md) {
                    padding: 0 var(--space-xl);

                    &::before {
                        content: "";
                        position: absolute;
                        width: 1px;
                        height: 100%;
                        left: 0;
                        top: 0;
                        background-color: var(--primary);
                    }

                    &::after {
                        content: "";
                        position: absolute;
                        width: 1px;
                        height: 100%;
                        right: 0;
                        top: 0;
                        background-color: var(--primary);
                    }
                }
            }

            .footer_actu {
                text-align: center;

                @include breakpoint(md) {
                    text-align: left;
                }

                .rs_valeurp {
                    font-size: 11px;

                    .rs {
                        display: flex;
                        gap: var(--space-sm);
                        justify-content: center;
                        margin-bottom: var(--space-lg);

                        legend {
                            margin-top: var(--space-xs);
                        }
                    }
                }
            }

            .footer_actu {

                .followActu {

                    h4 {
                        font-weight: 600;
                    }

                    .promethee {
                        height: auto;
                        display: block;
                        margin: var(--space-xs) 0 var(--space-sm);
                    }

                    .lien_footer {
                        font-weight: 300;
                        font-size: 12px;
                        color: var(--fixed-white);
                        line-height: 1.2;
                        a {
                            font-weight: 300;
                        }
                    }
                }
            }
        }
    }
}

@include breakpoint(md) {
    footer {

        .container {
            overflow: hidden;
        }

        .footer-container {
            display: inline;
            color: var(--fixed-white);
            text-align: center;

            .horizontal_line {
                display: none;
            }

            .head_footer {
                text-align: start;
                width: 100%;
            }

            .nav_footer {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;

                .footer_actu {
                    .rs_valeurp {
                        position: absolute;
                        top: var(--space-xxl);
                        right: var(--space-xxxl);
                        display: flex;
                        justify-content: center;
                        gap: var(--space-lg);
                        flex-direction: row-reverse;

                        .rs>img {
                            height: 34px;
                            width: 34px;
                        }

                        .rs>legend {
                            display: none;
                        }
                    }
                }

                .page_link {

                    ul>.menu-item-has-children {
                        text-align: start;

                        ul {
                            margin-top: var(--space-md);
                        }
                    }
                }

                .contact_footer {
                    text-align: start;

                    .telephone {
                        margin: var(--space-md) 0;
                    }
                }
            }
        }
    }
}