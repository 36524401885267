/*
————————————————
REPLACE AFTER STARTER ENDED
————————————————
*/
.td-u {
	position: relative;
	color: var(--secondary_grey);

	&:after {
		content: "";
		position: absolute;
		height: 1px;
		width: 100%;
		background: var(--secondary_grey);
		bottom: 0;
		left: 0;
	}
}

// section {
// padding: var(--space-md) 0;
// }

// legend {
// 	padding-bottom: var(--space-sm);
// }

article {
	a {
		display: flex;
		flex-direction: column;
	}
}

// h1,
// h2,
// h3,
// h4 {
// 	text-transform: uppercase;
// }