@include breakpoint(md) {
    .page-id-203 .app>.logo_container {
        display: none;
    }
}

.gestion_locative {
    min-height: 100vh;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    .gestion_locative_left_bloc,
    .gestion_locative_right_bloc {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        box-sizing: border-box;
        padding: var(--space-xxl) var(--space-xxxl);


        .title_bloc_gestion {
            background: rgba(29, 36, 41, 0.7);
            padding: var(--space-md) var(--space-lg);

            @include breakpoint(md) {
                background: rgba(29, 36, 41, 0.9);
                margin: auto 0;
                padding: var(--space-xxl) var(--space-xl);
            }

            .title_gestion_locative {
                font-size: var(--text-md);
                text-transform: uppercase;
                text-align: center;
                font-weight: 600;

                @include breakpoint(md) {
                    text-align: left;
                    font-size: var(--text-xl);
                    padding-bottom: var(--space-xs);
                }
            }
        }

        .gestion_border_bot {
            border: 1px solid var(--primary);
            width: 100%;
            margin: auto;
            margin-top: var(--space-xs);
            display: block;
            position: relative;
        }
    }

    .gestion_locative_left_bloc {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        object-fit: contain;
        color: var(--fixed-white);
        text-transform: uppercase;
        position: relative;
        justify-content: space-between;
        padding: var(--space-xxl) var(--space-lg);
        align-self: flex-start;

        min-height: 70vh;

        @include breakpoint(md) {
            min-height: 100vh;
            flex-basis: 50%;
        }

        @include breakpoint(md) {
            padding: var(--space-lg) var(--space-xxxl) var(--space-xxl);
        }

        * {
            position: relative;
        }

        &::before {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            background: var(--fixed-black);
            opacity: .3;
            z-index: 0;
            inset: 0;
        }

        @include breakpoint(md) {
            position: sticky;
            top: 0;
        }

        .logo_container {
            display: none;

            @include breakpoint(md) {
                display: block;
                padding: 0;
                margin: 0 0 var(--space-lg);
            }
        }

        .link_left_bloc {
            cursor: pointer;
            text-align: center;
            font-size: 11px;

            @include breakpoint(md) {
                align-self: start;
                font-size: var(--text-sm);
            }

            .link_conseil {
                text-decoration: underline;
            }
        }

        .invisible {
            opacity: 0;
            visibility: hidden;
        }
    }

    .gestion_locative_right_bloc {
        padding: var(--space-lg);

        @include breakpoint(md) {
            flex-basis: 50%;
        }

        @include breakpoint(md) {
            padding: var(--space-xl) var(--space-xxl) var(--space-lg) var(--space-xl);
        }

        .gestion_locative_step {
            height: 100%;

            .gestion_locative_header {
                @include breakpoint(md) {
                    width: fit-content;
                    margin-right: var(--space-xxl);
                }

                .gestion_locative_title_header {
                    font-family: var(--font-title);
                    font-size: var(--text-md);
                    font-weight: bold;
                    text-transform: uppercase;

                    @include breakpoint(md) {
                        font-size: var(--text-lg);
                    }
                }
            }

            .gestion_locative_header+h3 {
                font-size: .85rem;
                text-align: left;
                padding: var(--space-md) 0;
                font-weight: bold;
                font-style: italic;
                color: var(--primary);

                @include breakpoint(md) {
                    font-size: 1.125rem;
                }
            }

            .description_point {
                font-size: var(--text-base-size);
                font-weight: 400;
            }
        }

        .points {
            .points_title {
                font-size: var(--text-base-size);
                font-weight: 500;

                @include breakpoint(sm) {
                    font-size: var(--text-md);
                }
            }

            .point_1,
            .point_2 {
                display: flex;
                margin-top: var(--space-md);
                align-items: center;

                .sigle_step {
                    height: 30px;
                    width: 30px;
                    margin: var(--space-xs) var(--space-sm) var(--space-sm) 0;

                    @include breakpoint(sm) {

                        height: 40px;
                        width: 40px;
                    }

                    @include breakpoint(md) {
                        margin: 0 var(--space-lg) var(--space-sm) 0;
                        height: 57px;
                        width: 57px;
                    }
                }

                .point_contenu+p {
                    margin-bottom: var(--space-sm);
                    font-weight: 300;
                    font-size: var(--text-sm);

                    @include breakpoint(md) {
                        font-weight: 400;
                        font-size: var(--text-base-size);
                    }
                }
            }

            .contact_points {
                margin-top: var(--space-xl);
                text-align: center;

                @include breakpoint(md) {
                    margin: var(--space-xl) 0 var(--space-md);
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    justify-content: flex-end;
                    text-align: right;
                    padding: var(--space-sm) 0;
                }

                p {
                    font-style: italic;
                    font-weight: 500;
                    font-size: .85rem;

                    @include breakpoint(md) {
                        margin-right: var(--space-lg);
                        font-size: var(--text-sm);
                    }
                }

                .point_btn_contact_us {
                    display: inline-block;
                    margin: var(--space-md) 0;
                    padding: var(--space-md) var(--space-lg);
                    background: var(--primary);
                    color: var(--fixed-white);
                    text-transform: uppercase;
                    font-weight: 800;
                    text-align: center;

                    @include breakpoint(md) {
                        padding: var(--space-sm) var(--space-md);
                        justify-content: space-between;
                        margin: 0;
                    }
                }
            }
        }
    }
}

.with_scroll_top {
    @include breakpoint(md) {
        .section_scroll_top {
            display: none;
            visibility: hidden;
        }
    }
}