/*
-------------------------------- 
HEADER LAYOUT
--------------------------------
*/
.logo_container {
  position: absolute;
  padding: var(--space-lg) 0;
  z-index: 1;
  width: 100%;
  text-align: center;
  // mix-blend-mode: exclusion;

  @include breakpoint(md) {
    text-align: left;
    padding: var(--space-lg) var(--space-xxxl) 0 var(--space-xxxl);
    margin: auto;
  }

  .desktop_title_header {
    text-transform: uppercase;
    font-weight: bold;
    font-family: var(--font-title);
    letter-spacing: 0.4em;
    text-decoration: none;

    @include breakpoint(md) {
      font-size: 1.125rem;
    }
  }
}

header {
  position: fixed;
  bottom: 0;
  left: 0;
  height: fit-content;

  z-index: $header_level;
  width: 100%;
  padding-top: var(--space-lg);
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(244, 243, 234, 0.8));

  @include breakpoint(md) {
    padding-top: 0;
  }

  &:before {
    content: "";
    @include size(100%, 100%);
    @include absolute();
    background: var(--primary_grey);
    transform: translateY(-100%);
    transition: transform 0.8s $easeCustom;
  }

  .header-logo {
    position: relative;

    .desktop_title_header {
      display: none;

      path {
        fill: var(--secondary);
        transition: fill 0.4s ease;
      }
    }
  }

  .menu {
    display: flex;
    justify-content: center;
    gap: 20px;
    position: relative;
    z-index: 999;

    @include breakpoint(md) {
      padding-top: var(--space-lg);
    }
  }

  /*
    -------------------------------- 
    Main Header nav
    --------------------------------
    */
  .header-container {
    position: relative;
    // height: 0;
    display: flex;
    justify-content: center;
    transition: color 1s ease;

    @include breakpoint(md) {
      justify-content: flex-end;
      height: 0;

      &::after {
        display: none;
      }
    }

    &::after {
      content: "";
      position: absolute;
      top: 23px;
      width: 100%;
      height: 1px;
      border-bottom: 2px solid var(--primary);
      z-index: 950;

      @include breakpoint(md) {
        z-index: -1;
      }
    }

    #header-nav {
      display: none;

      ul {
        display: flex;

        li {
          &:not(:last-child) {
            margin-right: var(--space-md);
          }
        }
      }

      @include breakpoint(sm) {
        display: flex;
      }

      /*
            -------------------------------- 
            Language Selector
            --------------------------------
            */
      .language-selector {
        margin-left: var(--space-sm);

        .language-item {
          text-transform: uppercase;

          &:first-child {
            margin-right: var(--space-xs);
          }

          &.active {
            opacity: 0.4;
          }
        }
      }
    }
  }

  /*
    -------------------------------- 
    Burger
    --------------------------------
    */

  .burger {
    cursor: pointer;
    width: 50px;
    height: 50px;
    padding: 14px 10px 10px;
    display: block;
    // margin-right: 20px;
    margin-bottom: 20px;
    border-radius: 50%;
    background: var(--primary);
    position: relative;
    transition: background-color 0.15s ease;

    &:hover {
      background: var(--primary-hover);
    }

    div {
      @include size(21px, 2px);
      background-color: var(--fixed-white);
      display: block;
      margin: 4px auto;
      transition:
        transform 0.64s $easeOutExpo,
        opacity 0.24s $easeOutExpo,
        background-color 0.24s ease;
    }

    @include breakpoint(sm) {
      display: block;
    }
  }

  .contact {
    height: 0;
    cursor: pointer;
    display: block;
    border-radius: 50%;
    position: relative;

    a {
      color: var(--primary);
      transition: color 0.15s ease;

      &:hover {
        color: var(--primary-hover);
      }
    }
  }

  /*
    -------------------------------- 
    On scroll
    --------------------------------
    */
  &.is-scrolled {
    .header-logo {
      svg {
        path {
          fill: var(--secondary);
        }
      }
    }

    .header-container {
      color: var(--secondary);
    }

    &:before {
      transform: translateY(0%);
    }
  }
}

/*
-------------------------------- 
Nav Fullscreen
--------------------------------
*/
#nav-fullscreen {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  background: var(--fixed-white);
  z-index: $navFullscreen_level;
  text-transform: uppercase;
  color: var(--fixed-dark);
  display: block;
  // pointer-events: none;
  transform: translateX(100%);

  @include breakpoint(md) {
    background: rgba(0, 0, 0, 0.9);
    color: var(--fixed-white);
    width: 30vw;

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: -1;
      backdrop-filter: blur(3px);
    }
  }

  nav {
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    padding: 0 var(--space-md);

    & > .header_transition {
      border: 1px solid var(--primary);
      width: 100%;

      @include breakpoint(md) {
        border: none;
      }

      .menu-item-has-children {
        .header_transition {
          border: none;
          text-align: center;

          li {
            a {
              font-size: 0.75em;
              font-weight: 500;

              @include breakpoint(md) {
                font-size: 1.125em;
              }
            }
          }
        }
      }

      & > .dropdown_item {
        padding: var(--space-md) 0;

        @include breakpoint(md) {
          padding: 0;
        }

        li {
          a {
            @include breakpoint(md) {
              font-size: var(--text-xl);
            }
          }
        }
      }
    }

    ul {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .menu-item-has-children {
        margin-bottom: var(--space-xl);
        position: relative;
        text-align: center;

        @include breakpoint(md) {
          text-align: right;

          &::before {
            display: none;
          }
        }

        &::before {
          content: "";
          position: absolute;
          width: 1px;
          height: 40px;
          background-color: var(--fixed-black);
          left: 50%;
          top: 105%;
        }

        .dropdown_menu:first-child {
          border: none;
        }

        .header_transition {
          max-height: 0;
          overflow: hidden;
          transition: all 0.4s;

          .dropdown_menu {
            text-align: center;
            line-height: 1.2;

            @include breakpoint(md) {
              text-align: end;
              line-height: 1.3;
            }
          }
        }
      }

      .menu-item-has-children.active {
        .header_transition {
          visibility: visible;

          & > ul {
            display: block;

            @include breakpoint(md) {
              text-align: right;
            }
          }
        }
      }

      .menu-item-has-children.active > li {
        display: block;
        font-size: var(--text-lg);
      }

      li {
        text-transform: uppercase;

        a {
          font-size: 1.325rem;

          @include breakpoint(md) {
            font-size: 2.375em;
          }
        }
      }
    }

    .language-selector {
      margin-top: var(--space-lg);

      .language-item {
        text-transform: uppercase;
        font-size: var(--text-md);

        &:first-child {
          margin-right: var(--space-xs);
        }

        &.active {
          opacity: 0.4;
        }
      }
    }
  }
}

body {
  &.home {
    header {
      .header-logo {
        user-select: none;
        pointer-events: none;
      }
    }
  }
}

/*
-------------------------------- 
Body is Menu
--------------------------------
*/
.is-menu {
  header {
    .header-logo {
      svg {
        path {
          fill: var(--fixed-white);
        }
      }
    }

    .burger {
      background-color: var(--primary);

      @include breakpoint(md) {
        background: var(--fixed-white);
      }

      div {
        background: var(--fixed-white);

        @include breakpoint(md) {
          background-color: var(--primary);
        }

        &:nth-child(2) {
          opacity: 0;
        }

        &:first-child {
          transform: translateY(6px) rotate(-45deg);
        }

        &:last-child {
          transform: translateY(-6px) rotate(45deg);
        }
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .line_header {
    position: fixed;
    z-index: 1;
    top: 0;
    right: 50px;
    height: 100vh;
    // border-right: 2px solid var(--primary);
    width: 2px;
    background: var(--primary);
    // min-height: 100vh;
  }

  header {
    // padding-top: var(--space-lg);
    background-image: none;
    top: 0;

    .burger {
      margin-right: 25px;
      // margin-bottom: 20px;
    }

    .burger::before {
      content: "Menu";
      position: absolute;
      top: 50%;
      right: 100%;
      transform: translate(-1em, -50%);
      color: var(--fixed-white);
    }

    .contact {
      margin-right: 25px;
    }

    .menu {
      display: block;
    }

    .header-logo {
      position: relative;

      .desktop_title_header {
        display: block;
        text-transform: uppercase;
        font-family: "SilkSerif";
        font-weight: 700;
        font-size: 18px;
        letter-spacing: 3px;
        color: var(--fixed-white);
        margin-left: var(--space-xl);

        @include breakpoint(xl) {
          margin-left: var(--space-xxl);
        }

        path {
          fill: var(--secondary);
          transition: fill 0.4s ease;
        }
      }
    }
  }

  #nav-fullscreen {
    nav {
      flex-direction: row;
      justify-content: end;
      text-align: end;
      padding-bottom: var(--space-md);

      .dropdown_menu:first-child {
        border: none;
      }

      ul {
        align-items: end;

        .menu-item-has-children {
          &::after {
            display: none;
          }
        }

        .menu-item-has-children.active {
          & > .underline {
            &::before {
              rotate: 180deg;
              right: calc(100% + 12px);
            }
          }
        }

        .menu-item-has-children > a {
          &:before {
            content: "";
            position: absolute;
            height: 100%;
            width: 26px;
            background-repeat: no-repeat;
            rotate: 90deg;
            // left: -66px;
            right: 100%;
            top: 0;
            background-image: url("../img/utils/arrow.svg");
            background-position: left;
            background-size: contain;
            pointer-events: initial;
            transition: 0.2s ease;
          }
        }
      }
    }
  }
}
