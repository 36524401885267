/*
-------------------------------- 
TYPOGRAPHY
--------------------------------
*/
:root {
    --font-content: "Montserrat", sans-serif;
    --font-title: "SilkSerif", serif;

    /* set base values */
    --text-base-size: 1rem;
    --text-scale-ratio: 1.25;

    /* type scale */
    --text-xs: calc(1em / (var(--text-scale-ratio) * var(--text-scale-ratio)));
    --text-sm: calc(1em / var(--text-scale-ratio));
    --text-md: calc(1em * var(--text-scale-ratio));
    --text-lg: calc(1em * var(--text-scale-ratio) * var(--text-scale-ratio));
    --text-xl: calc(1em * var(--text-scale-ratio) * var(--text-scale-ratio) * var(--text-scale-ratio));
    --text-xxl: calc(
        1em * var(--text-scale-ratio) * var(--text-scale-ratio) * var(--text-scale-ratio) * var(--text-scale-ratio)
    );
    --text-xxxl: calc(
        1em * var(--text-scale-ratio) * var(--text-scale-ratio) * var(--text-scale-ratio) * var(--text-scale-ratio) *
            var(--text-scale-ratio)
    );

    /* line-height */
    --line-height-xs: 0.5;
    --heading-line-height: 1.2;
    --body-line-height: 1.4;
}

.text--xxxl {
    font-size: var(--text-xxxl);
}

.text--xxl {
    font-size: var(--text-xxl);
}

.text--xl {
    font-size: var(--text-xl);
}

.text--lg {
    font-size: var(--text-lg);
}

.text--md {
    font-size: var(--text-md);
}

.text--sm,
small {
    font-size: var(--text-sm);
}

.text--xs {
    font-size: var(--text-xs);
}

p {
    line-height: var(--body-line-height);
}



