#element-to-print {
    
    font-family: "Montserrat";

    .mainElementsPdf {
        padding: 44px 36px;
        font-size: 12px;

        .clearfix {
            overflow: auto;
            zoom: 1;
        }

        .page1 {

            .bigTitlePdf {
                text-transform: uppercase;
                font-family: "SilkSerif";
                font-size: 20px;
                letter-spacing: .4em;
                text-align: center;
                font-weight: bold;
                margin-bottom: 50px;
            }

            .pdfHeader {
                

                .titreOffrePdf {
                    .descriptionPdf {
                        font-size: 24px;
                        font-weight: 600;
                    }

                    .complementPdf {
                        font-size: 24px;
                        font-weight: 500;
                    }
                }

                .prixPdf {
                    float: right;
                    font-size: 28px;
                    font-weight: bold;

                    span {
                        font-size: 18px;
                        font-weight: 500;
                    }
                }
            }

            .picturePdf {
                margin: 20px 0 50px;
                img {
                    width: 100%;
                }
            }

            .descriptifPdf {
                td {
                    vertical-align: top;
                }

                .leftBloc {
                    width: 50%;
                    padding-right: 8px;
                    page-break-after: avoid;
                    margin-bottom: 0;

                    .content {
                        width: 80%;
                    }

                    .titleDetailsPdf {
                        border-bottom: 1px solid #3E9AB2;
                        padding-bottom: 8px;

                        div {
                            font-size: 15px;
                            font-weight: 500;
                        }
                    }

                    .detailsPdf {
                        margin-top: 20px;

                        .detailBien {
                            justify-content: space-between;
                            padding-bottom: 10px;
                            

                            .superficie {
                                font-weight: 600;
                                text-align: right;
                                padding-left: 5px;
                            }
                        }
                    }
                }

                .rightBloc {
                    width: 50%;
                    padding-left: 8px;

                    .titleContenuPdf {
                        border-bottom: 1px solid #3E9AB2;
                        padding-bottom: 8px;
                        div {
                            font-size: 15px;
                            font-weight: 500;
                        }
                    }

                    .contenuPdf {
                        margin-top: 20px;
                    }
                }
            }
        }

        .page2 {

            .pictureContentPdf {
                margin: 0 -8px 50px;
                .pdfImg {
                    width: 50%;
                    padding: 0 8px;
                    img {
                        width: 100%;
                    }
                }
            }

            .diagnosticPdf {
                margin-bottom: 240px;
                .titleDiagnosticPdf {
                    border-bottom: 1px solid #3E9AB2;
                    padding-bottom: 8px;

                    div {
                        font-size: 15px;
                        font-weight: 500;

                    }
                }

                .diagnosticPerf {
                    margin-top: 30px;
                    .containerDiagnostic {
                        position: relative;
                         width: 50px;
                         padding-left: 100px;

                        .goodPerf {
                            font-size: 11px;
                            color: #008F34;
                            font-weight: 600;
                        }

                        .badPerf {
                            font-size: 11px;
                            color: #E30C1C;
                            font-weight: 600;
                        }

                        .caracDiagnostic {
                            position: relative;
                            margin: 10px 0;
                            .indicator {
                                font-size: 14px;
                                font-weight: 500;
                                position: absolute;
                                height: 27px;
                                right: 100%;
                                top: 2px;
                                color: #fff;
                                background-color: #3E9AB2;
                                padding: 3px 5px 0;
                                transform: translateX(-21px);
                                white-space: nowrap;
                                &::before {
                                    position: absolute;
                                    content: '';
                                    right: 0;
                                    background-color: #3E9AB2;
                                    display: block;
                                    height: 21px;
                                    width: 21px;
                                    transform: translate(50%, 1px) rotate(45deg);
                                    border-top-left-radius: 2px;
                                    border-top-right-radius: 1px;
                                }
                                div {
                                    position: relative;
                                    z-index: 1;
                                    &:last-child {
                                        font-size: 6px;
                                    }
                                }
                                &.state0 {
                                    top: 2px;
                                }
                                &.state1 {
                                    top: 36px;
                                }
                                &.state2 {
                                    top: 70px;
                                }
                                &.state3 {
                                    top: 104px;
                                }
                                &.state4 {
                                    top: 138px;
                                }
                                &.state5 {
                                    top: 172px;
                                }
                                &.state6 {
                                    top: 206px;
                                }
                            }

                            .detailsDiagnostic {
                                display: flex;
                                justify-content: space-between;
                                padding: 2px;
                                width: fit-content;
                                .values {
                                    font-size: 14px;
                                    font-weight: 500;
                                    display: flex;
                                    align-items: center;
                                    justify-content: space-between;
                                    padding: 5px 5px 0;
                                    height: 25px;
                                    position: relative;

                                    &::before {
                                        position: absolute;
                                        left: 100%;
                                        display: block;
                                        height: 22px;
                                        width: 22px;
                                        top: 4px;
                                        content: '';
                                        transform: translateX(-50%) rotate(45deg);
                                        border-top-left-radius: 2px;
                                        border-top-right-radius: 1px;
                                    }
                                }

                                .details0 {
                                    width: 55px;
                                    background-color: #008f34;
                                    &::before {
                                        background-color: #008f34;
                                    }
                                }
                                .details1 {
                                    width: 85px;
                                    background-color: #379b00;
                                    &::before {
                                        background-color: #379b00;
                                    }
                                }
                                .details2 {
                                    width: 115px;
                                    background-color: #c9d300;
                                    &::before {
                                        background-color: #c9d300;
                                    }
                                }
                                .details3 {
                                    width: 145px;
                                    background-color: #fde700;
                                    &::before {
                                        background-color: #fde700;
                                    }
                                }
                                .details4 {
                                    width: 185px;
                                    background-color: #f9bb00;
                                    &::before {
                                        background-color: #f9bb00;
                                    }
                                }
                                .details5 {
                                    width: 215px;
                                    background-color: #ea6200;
                                    &::before {
                                        background-color: #ea6200;
                                    }
                                }
                                .details6 {
                                    width: 245px;
                                    color: var(--fixed-white);
                                    background-color: #e30c1c;
                                    &::before {
                                        background-color: #e30c1c;
                                    }
                                }
                                .classeEn {
                                    font-weight: 700;
                                    position: relative;
                                    font-size: 16px;
                                    float: right;
                                }
                            }
                        }
                    }

                    .containerEmission {
                        position: relative;
                        width: 50px;
                        padding-left: 100px;

                        .lowEmission {
                            font-size: 11px;
                            font-weight: 500;
                            color: #B995C1;
                        }

                        .highEmission {
                            font-size: 11px;
                            font-weight: 500;
                            color: #69408E;
                        }

                        .emissionTable {
                            margin: 10 0;
                            position: relative;
                            .indicatorEmission {
                                font-size: 14px;
                                font-weight: 500;
                                position: absolute;
                                height: 27px;
                                right: 100%;
                                top: 2px;
                                color: #fff;
                                background-color: #3E9AB2;
                                padding: 3px 5px 0;
                                transform: translateX(-21px);
                                white-space: nowrap;
                                &::before {
                                    position: absolute;
                                    content: '';
                                    right: 0;
                                    background-color: #3E9AB2;
                                    display: block;
                                    height: 21px;
                                    width: 21px;
                                    transform: translate(50%, 1px) rotate(45deg);
                                    border-top-left-radius: 2px;
                                    border-top-right-radius: 1px;
                                }
                                div {
                                    position: relative;
                                    z-index: 1;
                                    &:last-child {
                                        font-size: 6px;
                                    }
                                }
                                &.state0 {
                                    top: 2px;
                                }
                                &.state1 {
                                    top: 36px;
                                }
                                &.state2 {
                                    top: 70px;
                                }
                                &.state3 {
                                    top: 104px;
                                }
                                &.state4 {
                                    top: 138px;
                                }
                                &.state5 {
                                    top: 172px;
                                }
                                &.state6 {
                                    top: 206px;
                                }
                                
                            }

                            .detailsEmission {
                                display: flex;
                                justify-content: space-between;
                                padding: 2px;
                                width: fit-content;

                                --vp-height-details: 30px;

                                .values {
                                    font-size: 14px;
                                    font-weight: 500;
                                    display: flex;
                                    align-items: center;
                                    justify-content: space-between;
                                    padding: 5px 5px 0;
                                    height: 25px;
                                    position: relative;
                                }

                                .emission0 {
                                    width: 55px;
                                    background-color: #fdf1f5;
                                    &::before {
                                        background-color: #fdf1f5;
                                    }
                                }

                                .emission1 {
                                    width: 85px;
                                    background-color: #d9c1db;
                                    &::before {
                                        background-color: #d9c1db;
                                    }
                                }

                                .emission2 {
                                    width: 115px;
                                    background-color: #c7a9cd;
                                    &::before {
                                        background-color: #c7a9cd;
                                    }
                                }

                                .emission3 {
                                    width: 145px;
                                    background-color: #b995c1;
                                    &::before {
                                        background-color: #b995c1;
                                    }
                                }

                                .emission4 {
                                    width: 185px;
                                    background-color: #9f74ad;
                                    &::before {
                                        background-color: #9f74ad;
                                    }
                                }

                                .emission5 {
                                    width: 215px;
                                    background-color: #825a9d;
                                    &::before {
                                        background-color: #825a9d;
                                    }
                                }

                                .emission6 {
                                    width: 245px;
                                    color: var(--fixed-white);
                                    background-color: #69408e;
                                    &::before {
                                        background-color: #69408e;
                                    }
                                }

                                .classeEn {
                                    font-weight: 700;
                                    position: relative;
                                    font-size: 16px;
                                    float: right;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .footerPdf {
        position: fixed;
        bottom: 0;
        left: 0;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        object-fit: cover;
        width: 100%;

        .containerFooterPdf {
            background-color: rgba(29, 36, 41, 0.9);
            position: relative;
            color: #F4F3EA;
            text-align: center;
            padding: 40px 15px 45px;

            .logoFooterPdf {
                text-transform: uppercase;
                font-family: "SilkSerif";
                font-size: 18px;
                letter-spacing: .4em;
                text-align: center;
                font-weight: bold;
                margin-bottom: 20px;
            }

            .addressFooterPdf {

                .address,
                .siteWeb {
                    font-weight: 600;
                    font-size: 11px;
                }

                .siteWeb {
                    margin-bottom: 9px;
                }
            }

            .informationsFooterPdf {
                font-size: 8px;
                font-weight: 600;
            }
        }
    }
}

