.offres_page {
  .projects-section {
    margin-top: var(--space-xxxl);
  }
}

.entete_offre {
  text-align: center;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 500;

  a {
    font-weight: 500;
  }

  @include breakpoint(md) {
    text-align: left;
    font-size: 0.875rem;
  }
}

.categorie_taxons {
  padding: var(--space-xxl) var(--space-lg) 0;

  @include breakpoint(md) {
    padding: var(--space-xxl) var(--space-xxxl) 0 var(--space-xxxl);
  }
}

.loaded-posts {
  display: grid;
  justify-content: space-between;
  grid-template-columns: repeat(1, 1fr);
  margin: var(--space-xl) 0;
  gap: var(--space-xl);

  @include breakpoint(md) {
    grid-template-columns: repeat(3, 1fr);
    gap: var(--space-xl);
    padding: 0;
    margin: var(--space-xxl) 0;
  }
}

.taxons_link {
  padding: 0.75rem 1.25rem;
  color: white;
  background-color: var(--fixed-black);
  text-transform: uppercase;
  font-weight: bold;
  font-size: var(--text-sm);
  border-radius: 50px;
  h2 {
    font-size: inherit;
  }

  &:last-child {
    margin-left: 10px;
    position: relative;

    &::before {
      content: "";
      width: 1px;
      height: 70%;
      position: absolute;
      background-color: var(--fixed-black);
      top: 7px;
      right: 100%;
      margin-right: 10px;

      @include breakpoint(md) {
        margin-right: 15px;
      }
    }
  }

  @include breakpoint(md) {
    padding: 1rem var(--space-lg);
    font-size: var(--text-base-size);
    margin-right: var(--space-md);
  }
}

.pastille {
  display: inline-block;
  padding: 0.25rem 0.75rem;
  color: white;
  background-color: var(--primary);
  text-transform: uppercase;
  font-weight: 500;
  font-size: var(--text-sm);
  border-radius: 9999px;
  margin-top: 1rem;
  @include breakpoint(md) {
    font-size: var(--text-base-size);
  }
}

.header_categories {
  z-index: 949;
  position: relative;
  display: block;

  @include breakpoint(md) {
    padding-top: var(--space-md);
  }
}

.taxons {
  margin-top: var(--space-md);
  margin-bottom: var(--space-md);
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.bloc_header_offres {
  margin: 17px 0 var(--space-lg);

  @include breakpoint(md) {
    margin: var(--space-lg) 0 0;
  }

  h1 {
    font-size: var(--text-md);
    font-weight: 600;

    @include breakpoint(md) {
      font-size: var(--text-xl);
    }
  }
}

._current-cat {
  background-color: var(--primary);
}
