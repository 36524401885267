.pagination_offres {
    .pagination-container {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0;

        li {
            font-size: var(--text-md);
            font-weight: 600;
            @include breakpoint(md) {
                font-size: var(--text-lg);
            }
            &:not(:first-child):not(:last-child):not(:nth-last-child(2))::after {
                content: '–';
                display: inline-block;
                margin: 0 var(--space-sm);
                font-weight: 600;
            }
            &:first-child {
                padding-right: 2rem;
            }
            &:last-child {
                padding-left: 2rem;
            }
            .underline {
                .next_svg {
                    rotate: 90deg;
                }

                .prev_svg {
                    rotate: -90deg;
                }
            }

            .page-number {
                color: var(--fixed-black);
                font-weight: 600;
                position: relative;
            }

            .current {
                pointer-events: none;
                color: var(--primary);
                text-decoration: underline;
                text-underline-offset: .4em;
            }

            .disable {
                visibility: hidden;
            }
        }
    }
}