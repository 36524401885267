/*
-------------------------------- 
BREAKPOINTS
--------------------------------
*/
:root{
  --breakpoint-xs: 512px;
  --breakpoint-sm: 768px;
  --breakpoint-md: 1024px;
  --breakpoint-lg: 1280px;
  --breakpoint-xl: 1440px;

  --max-width-xxxl: 1920px;
}

$breakpoints: (
  xs: 512px,
  sm: 768px,
  md: 1024px,
  lg: 1280px,
  xl: 1440px
) !default;

@mixin breakpoint($breakpoint, $logic: false) {
  @if( $logic ) {
    @media #{$logic} and (min-width: map-get($map: $breakpoints, $key: $breakpoint)) { @content; }
  } @else {
    @media (min-width: map-get($map: $breakpoints, $key: $breakpoint)) { @content; }
  }
}