@include breakpoint(md) {
  .page-template-layoutNosServices .app > .logo_container {
    display: none;
  }
}

.nos_services {
  a {
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
  min-height: 100vh;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;

  .bloc_with_image,
  .bloc_without_image {
    width: 100%;
    min-height: 70vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
    padding: var(--space-xxl) var(--space-xxl);

    @include breakpoint(md) {
      min-height: 100vh;
      flex-basis: 50%;
    }

    .nos_services_titles {
      background: rgba(29, 36, 41, 0.7);
      padding: var(--space-md) var(--space-lg);

      @include breakpoint(md) {
        background: rgba(29, 36, 41, 0.9);
        margin: auto 0;
        padding: var(--space-xxl) var(--space-xl);
      }

      .nos_services_title {
        text-transform: uppercase;
        text-align: center;
        font-weight: 600;
        font-size: var(--text-md);

        @include breakpoint(md) {
          text-align: left;
          font-size: var(--text-xl);
          padding-bottom: var(--space-xs);
        }
      }
    }

    .conseil_border_bot {
      border: 1px solid var(--primary);
      width: 100%;
      margin: auto;
      margin-top: var(--space-xs);
      display: block;
      position: relative;
      float: none;

      @include breakpoint(md) {
        float: left;
      }
    }
  }

  .bloc_with_image {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    object-fit: contain;
    color: var(--fixed-white);
    text-transform: uppercase;
    position: relative;
    justify-content: space-between;
    padding: var(--space-xxl) var(--space-lg);

    @include breakpoint(md) {
      padding: var(--space-lg) var(--space-xxxl) var(--space-xxl);
    }

    * {
      position: relative;
    }

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background: var(--fixed-black);
      opacity: 0.3;
      z-index: 0;
      inset: 0;
    }

    @include breakpoint(md) {
      position: sticky;
      top: 0;
    }

    .logo_container {
      display: none;

      @include breakpoint(md) {
        display: block;
        padding: 0;
        margin: 0 0 var(--space-lg);
      }
    }

    .nos_services_left_bloc {
      font-size: 11px;
      cursor: pointer;
      text-align: center;

      @include breakpoint(md) {
        align-self: start;
        font-size: var(--text-sm);
      }

      .link_conseil {
        text-decoration: underline;
      }
    }

    .invisible {
      opacity: 0;
      visibility: hidden;
    }
  }

  .bloc_without_image {
    padding: var(--space-lg);

    @include breakpoint(md) {
      padding: var(--space-xl) var(--space-xxl) var(--space-lg) var(--space-xl);
    }

    .container_nos_services_right {
      .nos_services_header {
        @include breakpoint(md) {
          width: fit-content;
          margin-right: var(--space-xxl);
        }

        .nos_services_header_steps {
          font-family: var(--font-title);
          font-size: var(--text-md);
          font-weight: bold;
          text-transform: uppercase;

          @include breakpoint(md) {
            font-size: var(--text-lg);
          }
        }
      }

      .nos_services_header + h3 {
        font-size: 0.85rem;
        text-align: left;
        padding: var(--space-md) 0;
        margin-top: var(--space-md);

        @include breakpoint(md) {
          font-size: 1.125rem;
        }

        p,
        span {
          font-weight: bold;
        }
      }
    }

    .nos_services_right_content {
      .content_right_section {
        p {
          margin-bottom: var(--space-md);
          font-weight: 300;
          font-size: var(--text-sm);

          @include breakpoint(md) {
            font-size: var(--text-base-size);
          }
        }
      }

      .nos_services_btn {
        margin-top: var(--space-xl);
        text-align: center;

        @include breakpoint(md) {
          margin: var(--space-xl) 0 var(--space-md);
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: flex-end;
          text-align: right;
          padding: var(--space-sm) 0;
        }

        p {
          font-style: italic;
          font-weight: 500;
          font-size: 0.85rem;

          @include breakpoint(md) {
            margin-right: var(--space-lg);
            font-size: var(--text-sm);
          }
        }

        .real_btn_nos_services {
          display: inline-block;
          margin: var(--space-md) 0;
          padding: var(--space-md) var(--space-lg);
          background: var(--primary);
          color: var(--fixed-white);
          text-transform: uppercase;
          font-weight: 800;
          text-align: center;

          @include breakpoint(md) {
            padding: var(--space-sm) var(--space-md);
            justify-content: space-between;
            margin: 0;
          }
        }
      }
    }
  }
}
