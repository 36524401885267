/*
-------------------------------- 
ARTICLE SINGLE
--------------------------------
*/

.single_offre {

    .container {

        padding: var(--space-xxl) var(--space-lg) 0;

        @include breakpoint(md) {
            padding: var(--space-xxl) var(--space-xxxl) 0 var(--space-xxxl);
        }

        .filAriane {
            text-align: center;
            text-transform: uppercase;
            font-size: 11px;
            position: relative;
            z-index: 10;

            @include breakpoint(md) {
                text-align: left;
                font-size: .875rem;
                margin: 0 0 var(--space-xl);
                padding-top: var(--space-md);
            }

            a {
                color: var(--primary);
                font-weight: 500;

                span {
                    font-weight: 500;
                    text-decoration: underline;
                    text-underline-offset: 4px;
                }
            }
        }

        .singleBien {
            margin: var(--space-md) 0 0;

            #introduction {
                display: flex;
                flex-wrap: wrap;

                .description {
                    @include breakpoint(md) {
                        width: 50%;
                        // padding: var(--space-lg) var(--space-xl);
                    }

                    .description_single {
                        border: 1px solid var(--primary);
                        padding: var(--space-md) var(--space-sm) 150px;

                        @include breakpoint(md) {
                            padding: var(--space-lg) var(--space-xxl);
                            border-width: 2px;
                        }

                        .titre_bien {
                            width: fit-content;
                            margin-bottom: var(--space-sm);
                            line-height: 1.23;

                            @include breakpoint(md) {
                                margin-bottom: var(--space-md);
                            }

                            .title_one {
                                font-size: var(--text-base-size);
                                font-weight: 600;

                                @include breakpoint(md) {
                                    font-size: var(--text-lg);
                                }
                            }

                            .complement {
                                font-size: .8125rem;
                                font-weight: 400;
                                margin-bottom: var(--space-xxs);

                                @include breakpoint(md) {
                                    font-size: var(--text-lg);
                                }
                            }

                            .trait {
                                border: solid var(--primary);
                                border-width: 1px 0 0;
                                width: 100%;

                                @include breakpoint(md) {
                                    border-width: 1px;
                                }
                            }
                        }

                        .price:not(span) {
                            font-size: var(--text-md);
                            font-weight: bold;
                            margin-bottom: var(--space-md);

                            @include breakpoint(md) {
                                margin-bottom: var(--space-xs);
                                font-size: var(--text-xxl);
                            }
                        }

                        .content {
                            font-weight: 300;
                            padding: 0;
                            font-size: var(--text-sm);

                            @include breakpoint(md) {
                                padding: 0 var(--space-xl) 0 0;
                                font-size: var(--text-base-size);
                            }
                        }

                        .buttons {
                            display: grid;
                            text-transform: uppercase;
                            margin: var(--space-lg) 0;
                            grid-template-columns: repeat(2, 1fr);
                            text-align: center;
                            gap: 10px;
                            // padding: var(--space-xs) var(--space-sm);

                            .download_btn,
                            .visite,
                            .contact_us {
                                padding: var(--space-xs) var(--space-sm);
                                color: var(--fixed-white);
                                background: var(--primary);
                                font-size: .875rem;
                                text-align: center;
                                display: block;
                                font-weight: 500;
                                font-size: .65rem;

                                @include breakpoint(md) {
                                    font-size: .875rem;
                                }
                            }

                            .informations {
                                text-transform: uppercase;
                                font-size: 9px;
                                font-style: italic;
                                align-self: center;
                                text-align: start;
                                font-weight: 500;

                                @include breakpoint(md) {
                                    font-size: .75rem;
                                }

                                p {
                                    font-weight: 500;
                                }
                            }
                        }
                    }
                }

                .myCarrousel {
                    width: 90%;
                    margin: auto;
                    margin-top: -120px;

                    @include breakpoint(sm) {
                        width: 70%;
                    }

                    @include breakpoint(md) {
                        margin: 0;
                        width: 50%;
                        transform: translateX(-40px);
                        display: flex;
                        margin-top: var(--space-xl);
                    }

                    .swip {

                        @include breakpoint(md) {
                            width: calc(80% - var(--space-lg));
                        }

                        .mySwiper {
                            position: relative;
                            height: 250px;
                            overflow: hidden;

                            @include breakpoint(sm) {
                                height: 350px;
                            }

                            @include breakpoint(xl) {
                                height: 450px;
                            }

                            .swiper-wrapper {

                                .swiper-slide {
                                    position: relative;
                                    height: 100%;

                                    img {
                                        position: absolute;
                                        inset: 0;
                                        width: 100%;
                                        height: 100%;
                                        display: block;
                                        object-fit: cover;
                                    }
                                }
                            }

                            .swiper-button-prev::after,
                            .swiper-button-next::after {
                                color: var(--fixed-white);
                            }
                        }
                    }

                    .swip2 {
                        width: 20%;
                        height: 350px;
                        margin-left: var(--space-lg);
                        display: none;

                        @include breakpoint(md) {
                            display: block;
                        }

                        .mySwiper2 {
                            height: 100%;
                            overflow: hidden;

                            .swiper-wrapper {

                                .swiper-slide {
                                    width: 100%;
                                    height: 80px;
                                    position: relative;
                                }

                                .swiper-slide-thumb-active {
                                    // opacity: 1;
                                    box-sizing: border-box;
                                    border: 3px solid var(--primary);
                                }

                                img {
                                    position: absolute;
                                    inset: 0;
                                    width: 100%;
                                    height: 100%;
                                    display: block;
                                    object-fit: cover;
                                }
                            }
                        }
                    }
                }
            }
        }

        .descriptionBien {
            margin-top: var(--space-xxl);

            @include breakpoint(md) {
                margin-top: var(--space-xxxl);
            }

            .containerDescription {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;

                .descriptif {
                    width: 100%;

                    @include breakpoint(md) {
                        width: 35%;
                    }

                    .carac {
                        .descriptifTitle {
                            border-bottom: 1.5px solid var(--primary);
                            margin-bottom: var(--space-md);

                            .title {
                                font-size: var(--text-md);
                                font-weight: 600;
                                margin-bottom: 10px;

                                @include breakpoint(md) {
                                    font-size: var(--text-lg);
                                }
                            }
                        }

                        .detailBien {
                            display: flex;
                            justify-content: space-between;
                            border-bottom: 1px solid rgba(62, 154, 178, .2);
                            margin-bottom: var(--space-md);
                            font-size: var(--text-sm);

                            @include breakpoint(md) {
                                font-size: var(--text-base-size);
                            }

                            div:first-child {
                                font-weight: 500;
                            }

                            div:last-child {
                                margin-bottom: var(--space-sm);
                                font-weight: 600;

                                @include breakpoint(md) {
                                    margin-bottom: var(--space-xs);
                                }
                            }
                        }
                    }
                }

                .diagnostic {

                    margin-top: var(--space-xxl);

                    @include breakpoint(md) {
                        margin-top: var(--space-xxxl);
                    }

                    @include breakpoint(md) {
                        width: calc(65% - var(--space-xxxl));
                        margin-top: 0;
                        margin-left: var(--space-xxxl);
                    }

                    .diagnosticTitle {
                        margin-bottom: var(--space-md);

                        @include breakpoint(md) {
                            border-bottom: 1px solid var(--primary);

                        }

                        .title {
                            font-size: var(--text-md);
                            font-weight: 600;
                            margin-bottom: 10px;

                            @include breakpoint(md) {
                                font-size: var(--text-lg);
                            }
                        }
                    }

                    .diagnosticPerf {
                        // width: 100%;
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: space-between;
                        gap: var(--space-xl);

                        @include breakpoint(sm) {
                            gap: var(--space-xxxl);
                        }

                        @include breakpoint(md) {
                            gap: 0;
                        }

                        .containerDiagnostic {
                            position: relative;

                            .goodPerf {
                                font-size: 11px;
                                color: #008F34;
                                font-weight: 600;

                                @include breakpoint(md) {
                                    font-size: var(--text-md);
                                }

                                @include breakpoint(xl) {
                                    font-size: var(--text-sm);
                                }
                            }

                            .badPerf {
                                font-size: 11px;
                                color: #E30C1C;
                                font-weight: 600;

                                @include breakpoint(md) {
                                    font-size: var(--text-md);
                                }

                                @include breakpoint(xl) {
                                    font-size: var(--text-sm);
                                }
                            }

                            .caracDiagnostic {
                                margin: var(--space-sm) 0;

                                @include breakpoint(md) {
                                    margin: var(--space-md) 0;
                                }

                                --vp-height-indicator: 40px;
                                --vp-height-indicator-desktop: 34px;

                                .indicator {
                                    font-size: var(--text-sm);
                                    font-weight: 500;
                                    position: absolute;
                                    width: fit-content;
                                    display: flex;
                                    justify-content: center;
                                    flex-direction: column;
                                    height: var(--vp-height-indicator);
                                    color: var(--fixed-white);
                                    background-color: var(--primary);
                                    padding: 0 var(--space-sm);
                                    text-align: center;
                                    white-space: nowrap;
                                    right: -70px;
                                    z-index: 10;

                                    @include breakpoint(md) {
                                        height: var(--vp-height-indicator-desktop);
                                        right: calc(100% + 25px);
                                        // font-size: var(--text-sm);
                                        // right: 100%;
                                    }

                                    &::before {
                                        position: absolute;
                                        content: '';
                                        left: 0;
                                        background-color: var(--primary);
                                        display: block;
                                        height: calc(var(--vp-height-indicator) / sqrt(2));
                                        width: calc(var(--vp-height-indicator) / sqrt(2));
                                        transform: translate(-50%) rotateZ(45deg);
                                        border-top-left-radius: 2px;
                                        border-top-right-radius: 1px;
                                        z-index: -1;

                                        @include breakpoint(md) {
                                            left: 100%;
                                            height: calc(var(--vp-height-indicator-desktop) / sqrt(2));
                                            width: calc(var(--vp-height-indicator-desktop) / sqrt(2));
                                        }
                                    }

                                    div {
                                        font-size: 6px;
                                    }

                                    &[data-index-number="0"] {
                                        top: 23px;
                                    }

                                    &[data-index-number="1"] {
                                        top: 58px;
                                    }

                                    &[data-index-number="2"] {
                                        top: 92px;
                                    }

                                    &[data-index-number="3"] {
                                        top: 126px;
                                    }

                                    &[data-index-number="4"] {
                                        top: 160px;
                                    }

                                    &[data-index-number="5"] {
                                        top: 194px;
                                    }

                                    &[data-index-number="6"] {
                                        top: 228px;
                                    }

                                    @include breakpoint(md) {

                                        &[data-index-number="0"] {
                                            top: 37px;
                                        }

                                        &[data-index-number="1"] {
                                            top: 71px;
                                        }

                                        &[data-index-number="2"] {
                                            top: 106px;
                                        }

                                        &[data-index-number="3"] {
                                            top: 140px;
                                        }

                                        &[data-index-number="4"] {
                                            top: 174px;
                                        }

                                        &[data-index-number="5"] {
                                            top: 208px;
                                        }

                                        &[data-index-number="6"] {
                                            top: 242px;
                                        }

                                    }
                                }

                                .detailsDiagnostic {
                                    display: flex;
                                    justify-content: space-between;
                                    padding: 2px;
                                    width: fit-content;

                                    --vp-height-details: 30px;

                                    .values {
                                        font-size: var(--text-sm);
                                        font-weight: 500;
                                        display: flex;
                                        align-items: center;
                                        justify-content: space-between;
                                        padding: 0 5px;
                                        height: var(--vp-height-details);
                                        background-color: var(--vp-background-color);
                                        position: relative;

                                        @include breakpoint(md) {
                                            font-size: 14px;
                                        }

                                        @include breakpoint(xl) {
                                            font-size: var(--text-sm);
                                        }

                                        &::before {
                                            position: absolute;
                                            left: 100%;
                                            background-color: var(--vp-background-color);
                                            display: block;
                                            height: calc(var(--vp-height-details) / sqrt(2));
                                            width: calc(var(--vp-height-details) / sqrt(2));
                                            content: '';
                                            transform: translate(-50%) rotateZ(45deg);
                                            border-top-left-radius: 2px;
                                            border-top-right-radius: 1px;
                                        }
                                    }

                                    .details0 {
                                        width: 55px;
                                        --vp-background-color: #008f34;
                                    }

                                    .details1 {
                                        width: 85px;
                                        --vp-background-color: #379b00;
                                    }

                                    .details2 {
                                        width: 115px;
                                        --vp-background-color: #c9d300;
                                    }

                                    .details3 {
                                        width: 145px;
                                        --vp-background-color: #fde700;
                                    }

                                    .details4 {
                                        width: 185px;
                                        --vp-background-color: #f9bb00;
                                    }

                                    .details5 {
                                        width: 215px;
                                        --vp-background-color: #ea6200;
                                    }

                                    .details6 {
                                        width: 245px;
                                        color: var(--fixed-white);
                                        --vp-background-color: #e30c1c;
                                    }

                                    .classeEn {
                                        font-weight: 800;
                                        position: relative;
                                        font-size: var(--text-md);

                                        @include breakpoint(md) {
                                            font-size: var(--text-lg);
                                        }
                                    }
                                }
                            }
                        }

                        .containerEmission {
                            position: relative;

                            .lowEmission {
                                font-size: 11px;
                                font-weight: 500;
                                color: #B995C1;

                                @include breakpoint(md) {
                                    font-size: var(--text-md);
                                }

                                @include breakpoint(xl) {
                                    font-size: var(--text-sm);
                                }
                            }

                            .highEmission {
                                font-size: 11px;
                                font-weight: 500;
                                color: #69408E;

                                @include breakpoint(md) {
                                    font-size: var(--text-md);
                                }

                                @include breakpoint(xl) {
                                    font-size: var(--text-sm);
                                }
                            }

                            .emissionTable {
                                position: relative;
                                margin: var(--space-sm) 0;

                                @include breakpoint(md) {
                                    margin: var(--space-md) 0;
                                }

                                --vp-height-indicator: 40px;
                                --vp-height-indicator-desktop: 34px;

                                .indicatorEmission {
                                    font-size: var(--text-sm);
                                    font-weight: 500;
                                    position: absolute;
                                    width: fit-content;
                                    display: flex;
                                    justify-content: center;
                                    flex-direction: column;
                                    height: var(--vp-height-indicator);
                                    color: var(--fixed-white);
                                    background-color: var(--primary);
                                    padding: 0 var(--space-sm);
                                    text-align: center;
                                    white-space: nowrap;
                                    right: -70px;
                                    z-index: 10;

                                    @include breakpoint(md) {
                                        height: var(--vp-height-indicator-desktop);
                                        right: calc(100% + 25px);
                                    }

                                    &::before {
                                        position: absolute;
                                        content: '';
                                        left: 0;
                                        background-color: var(--primary);
                                        display: block;
                                        height: calc(var(--vp-height-indicator) / sqrt(2));
                                        width: calc(var(--vp-height-indicator) / sqrt(2));
                                        transform: translate(-50%) rotateZ(45deg);
                                        border-top-left-radius: 2px;
                                        border-top-right-radius: 1px;
                                        z-index: -1;

                                        @include breakpoint(md) {
                                            left: 100%;
                                            height: calc(var(--vp-height-indicator-desktop) / sqrt(2));
                                            width: calc(var(--vp-height-indicator-desktop) / sqrt(2));
                                        }
                                    }

                                    div {
                                        font-size: 6px;
                                    }

                                    &[data-index-number="0"] {
                                        top: -3px;
                                    }

                                    &[data-index-number="1"] {
                                        top: 30px;
                                    }

                                    &[data-index-number="2"] {
                                        top: 65px;
                                    }

                                    &[data-index-number="3"] {
                                        top: 99px;
                                    }

                                    &[data-index-number="4"] {
                                        top: 133px;
                                    }

                                    &[data-index-number="5"] {
                                        top: 167px;
                                    }

                                    &[data-index-number="6"] {
                                        top: 201px;
                                    }

                                    @include breakpoint(md) {
                                        &[data-index-number="0"] {
                                            top: -1px;
                                        }

                                        &[data-index-number="1"] {
                                            top: 33px;
                                        }

                                        &[data-index-number="2"] {
                                            top: 68px;
                                        }

                                        &[data-index-number="3"] {
                                            top: 102px;
                                        }

                                        &[data-index-number="4"] {
                                            top: 135px;
                                        }

                                        &[data-index-number="5"] {
                                            top: 170px;
                                        }

                                        &[data-index-number="6"] {
                                            top: 204px;
                                        }
                                    }
                                }

                                .detailsEmission {
                                    display: flex;
                                    justify-content: space-between;
                                    padding: 2px;
                                    width: fit-content;

                                    --vp-height-details: 30px;

                                    .values {
                                        font-size: var(--text-sm);
                                        font-weight: 500;
                                        display: flex;
                                        align-items: center;
                                        justify-content: space-between;
                                        padding: 0 5px;
                                        height: var(--vp-height-details);
                                        background-color: var(--vp-background-color);
                                        position: relative;

                                        @include breakpoint(md) {
                                            font-size: 14px;
                                        }

                                        @include breakpoint(xl) {
                                            font-size: var(--text-sm);
                                        }
                                    }

                                    .emission0 {
                                        width: 55px;
                                        --vp-background-color: #fdf1f5;
                                    }

                                    .emission1 {
                                        width: 85px;
                                        --vp-background-color: #d9c1db;
                                    }

                                    .emission2 {
                                        width: 115px;
                                        --vp-background-color: #c7a9cd;
                                    }

                                    .emission3 {
                                        width: 145px;
                                        --vp-background-color: #b995c1;
                                    }

                                    .emission4 {
                                        width: 185px;
                                        --vp-background-color: #9f74ad;
                                    }

                                    .emission5 {
                                        width: 215px;
                                        --vp-background-color: #825a9d;
                                    }

                                    .emission6 {
                                        width: 245px;
                                        color: var(--fixed-white);
                                        --vp-background-color: #69408e;
                                    }

                                    .classeEn {
                                        font-weight: 800;
                                        position: relative;
                                        font-size: var(--text-md);

                                        @include breakpoint(md) {
                                            font-size: var(--text-lg);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .localisation {
            padding: 0 var(--space-md) var(--space-md);
            margin-top: var(--space-xxl);
            border: 1px solid var(--primary);
            display: flex;
            flex-wrap: wrap;

            @include breakpoint(md) {
                padding: var(--space-xxl);
                border-width: 2px;
            }

            .mapCarte {
                margin: -1px calc(-1 * (var(--space-md) + var(--space-lg))) 0;
                width: calc(100% + 2 * (var(--space-md) + var(--space-lg)));
                height: 380px;

                @include breakpoint(md) {
                    width: 60%;
                    margin: 0;
                }

                #map {
                    height: 100%;
                }
            }

            .infos {

                width: 100%;
                text-align: center;
                margin-top: var(--space-lg);

                @include breakpoint(md) {
                    width: calc(40% - var(--space-xl));
                    margin-left: var(--space-xl);
                    margin-top: 0;
                    text-align: start;
                }

                .titre {

                    border-bottom: 1px solid var(--primary);
                    width: fit-content;
                    margin: auto;

                    @include breakpoint(md) {
                        margin: 0;
                    }

                    .titreProximite {
                        font-family: var(--font-title);
                        font-size: var(--text-base-size);
                        font-weight: 700;
                        text-transform: uppercase;

                        @include breakpoint(md) {
                            font-size: var(--text-lg);
                        }
                    }
                }

                .infosLieu {
                    line-height: 3;
                    margin: var(--space-md) 0;
                    font-size: var(--text-sm);

                    @include breakpoint(md) {
                        font-size: var(--text-base-size);
                    }

                    .infosLieu_inner {
                        display: flex;
                        gap: var(--space-sm);

                        .logoLocalication {
                            display: flex;
                            align-items: center;
                            justify-content: cneter;

                            img {
                                width: 24px;
                                height: 24px;

                                @include breakpoint(md) {
                                    width: 36px;
                                    height: 36px;
                                }
                            }
                        }

                        .infosLocalisation {
                            font-weight: 500;

                            span {
                                font-weight: 300;
                            }
                        }
                    }
                }

                .plusInfo {
                    font-style: italic;
                    font-weight: 300;
                    font-size: var(--text-sm);

                    @include breakpoint(md) {
                        font-size: var(--text-base-size);
                    }
                }
            }
        }
    }
}