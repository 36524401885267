/*
-------------------------------- 
THEME DEFAULT
--------------------------------
*/
body,
html {
  width: 100%;
  box-sizing: border-box;
  -ms-scroll-chaining: none;
  overscroll-behavior: none;
}

body {
  font-size: var(--text-base-size);
  font-family: var(--font-content);
  background: var(--fixed-white);
  color: var(--fixed-black);
  min-height: 100vh;
  position: relative;
  line-height: var(--body-line-height);

  /* mobile viewport bug fix */
  min-height: -webkit-fill-available;
}

html {
  height: -webkit-fill-available;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: inherit;

  &:before,
  &:after {
    user-select: none;
    pointer-events: none;
  }

  &:focus {
    outline: none !important;
  }
}

a,
body,
button,
div,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
html,
img,
input,
label,
li,
nav,
p,
select,
span,
table,
textarea,
ul {
  margin: 0;
  padding: 0;
  border: 0;
  font-weight: normal;
}

img,
video {
  // width: 100%;
  // pointer-events: none;
  user-select: none;
}

a {
  text-decoration: none;
  color: inherit;
  height: fit-content;
}

ul {
  li {
    list-style: none;
  }
}

.page-template-templateslayoutNosServices-php,
.home,
.page-id-143,
.page-id-203 {
  .app {
    .logo_container {
      &>.header-logo {
        color: var(--fixed-white);
      }
    }
  }
}

.page-id-6 {
  .app {
    .logo_container {
      &>.header-logo {
        color: var(--fixed-black);

        @include breakpoint(md) {
          color: var(--fixed-white);
        }
      }
    }
  }
}

.archive,
.blog,
.page-id-12,
.page-id-458 {
  .app {
    .logo_container {
      &>.header-logo {
        color: var(--fixed-dark);
      }
    }
  }
}