/*
-------------------------------- 
CONTACT PAGE
--------------------------------
*/

.contact_page {
    min-height: 100vh;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    @include breakpoint(md) {
        flex-direction: row-reverse;
    }

    .contact_us,
    .form_contact {
        width: 100%;
        min-height: 70vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: var(--space-xxl) 0;

        @include breakpoint(md) {
            width: 50%;
            height: 100vh;
        }

    }

    .contact_us .first_bloc,
    .second_bloc {
        background: rgba(29, 36, 41, .9);
        height: 100%;
    }

    .contact_us {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        object-fit: contain;
        color: var(--fixed-white);
        position: relative;
        justify-content: space-around;
        padding: var(--space-lg);

        @include breakpoint(md) {
            position: sticky;
            padding: var(--space-xxl) var(--space-xxxl);
        }

        @include breakpoint(xl) {
            position: sticky;
            padding: var(--space-xxl) var(--space-xxxl) var(--space-xl);
        }

        * {
            position: relative;
        }

        &::before {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            background: var(--fixed-black);
            opacity: .3;
            z-index: 0;
            inset: 0;

        }

        .first_bloc,
        .second_bloc {
            width: 100%;
            height: auto;
            padding: var(--space-md);
            margin: 0 0 var(--space-md) 0;
            text-align: center;

            @include breakpoint(md) {
                padding: var(--space-lg) var(--space-lg);
                text-align: left;
            }

            .big_title_contact {
                width: fit-content;
                margin: auto;

                @include breakpoint(md) {
                    margin: 0;
                }

                .contact_us_title {
                    font-weight: 600;
                }

                .more_informations {
                    font-weight: 400;
                }
            }

            .big_title_contact h2,
            h3 {
                text-align: center;
                font-size: var(--text-md);
                font-weight: 400;

                @include breakpoint(md) {
                    text-align: left;
                    ont-size: var(--text-lg);
                }
            }


            .address_contact {
                font-size: var(--text-base-size);

                .rue {
                    margin: var(--space-md) 0 var(--space-xs);
                }

                .tel_fax {
                    margin: var(--space-md) 0;
                }
            }
        }

        .second_bloc .reseaux_s {
            display: flex;
            flex-direction: column;
            line-height: 2;
            margin-top: var(--space-md);

            @include breakpoint(md) {
                flex-direction: row;
                justify-content: space-between;
            }

            .res {
                display: flex;
                align-self: center;

                &:first-child {
                    margin-bottom: var(--space-lg);

                    @include breakpoint(md) {
                        margin-bottom: 0;
                    }

                }

                img {
                    margin: 0 10px 0;
                    height: 27px;
                    width: 27px;

                    @include breakpoint(sm) {
                        height: 34px;
                        width: 34px;
                        margin: 5px 10px 0 0;
                    }

                    @include breakpoint(md) {
                        height: 34px;
                        width: 34px;
                        margin: 0 10px 0 0;
                    }
                }
            }
        }
    }

    .form_contact {
        .form-container {
            width: 100%;
            padding: 0 var(--space-lg);

            @include breakpoint(md) {
                padding: 0 var(--space-xxxl);
            }

            .titles_form {
                margin: 0 0 var(--space-md);
                width: fit-content;

                @include breakpoint(md) {
                    margin: 0 0 var(--space-xl);
                }

                .big_titre_form {
                    font-family: var(--font-title);
                    font-size: var(--text-md);
                    font-weight: bold;
                    text-transform: uppercase;
                    margin: 0;

                    @include breakpoint(md) {
                        font-size: var(--text-lg);
                    }
                }
            }
        }
    }
}

.trait_contact {
    border: 1px solid var(--primary);
    width: 100%;
    margin: auto;
    margin: var(--space-xs) 0 0;
    display: block;

    @include breakpoint(md) {
        margin-bottom: var(--space-lg);
    }
}