.card-news>a:hover {
    .zoomEffect .img_article img {
        transform: scale(1.1);
    }

    .description>.plus_icon {
        transform: translateY(calc(-1 * (var(--space-md) + 50%)))scale(1.1);
    }
}

.actus {
    --color-title: #103348;
    --color-description: #192C24;

    .firstArticle {
        .container {
            .titleArticle {
                font-size: 1.25em;
                font-weight: 600;
                color: var(--color-big-title);
                margin: 17px 0;

                @include breakpoint(md) {
                    font-size: 2.5em;
                    margin: 30px 0 17px;
                }
            }
        }

        .cards-article {
            .card-news-first {
                position: relative;

                @include breakpoint(md) {
                    grid-column: 1 / 4;
                    grid-row: 1;
                }

                .item_news {
                    height: 100%;
                    pointer-events: none;

                    .actu-content {
                        display: flex;
                        flex-wrap: wrap;
                        position: relative;

                        .zoomEffect {
                            // position: absolute;
                            height: 50%;
                            width: 100%;
                            // overflow: hidden;

                            @include breakpoint(md) {
                                width: 50%;
                                height: 100%;
                            }


                            .img_article {
                                height: 100%;

                                @include breakpoint(md) {
                                    height: 100%;
                                }


                                img {
                                    transition: transform .2s ease;
                                    height: 100%;
                                    width: 100%;
                                    object-fit: cover;
                                }
                            }
                        }

                        .description {
                            width: 100%;
                            height: 50%;
                            color: var(--fixed-white);

                            @include breakpoint(md) {
                                height: 100%;
                                width: calc(50% - var(--space-lg));
                                position: relative;
                                padding-left: var(--space-lg)
                            }

                            .plus_icon {
                                display: none;
                            }

                            .container {
                                position: relative;
                                padding-top: 18px;
                                // padding: var(--space-lg) var(--space-xxxxl) 0 0;

                                @include breakpoint(md) {
                                    padding: var(--space-xl);
                                }

                                .category_item {
                                    font-size: 12px;
                                    font-weight: bold;
                                    color: var(--color-description);
                                    pointer-events: auto;

                                    @include breakpoint(md) {
                                        font-size: 1.875em;
                                    }
                                }

                                .description_actu {
                                    // margin-bottom: var(--space-lg);
                                    pointer-events: auto;

                                    .titre {
                                        font-size: 1.125em;
                                        font-weight: 500;
                                        color: var(--color-description);
                                        margin: 14px 0 38px;

                                        @include breakpoint(md) {
                                            margin: 20px 0 62px;
                                            font-size: 2em;
                                        }
                                    }
                                }

                                .infos {
                                    display: flex;
                                    color: var(--color-description);
                                    font-weight: 200;
                                    font-size: 10px;
                                    margin-bottom: 19px;

                                    @include breakpoint(md) {
                                        margin-bottom: 50px;
                                        font-size: 1.125em;
                                    }

                                    &>legend {
                                        margin: 0 4px;
                                    }
                                }

                                button {
                                    font-size: 12px;
                                    // width: 135px;
                                    // height: 38px;
                                    background-color: var(--primary);
                                    color: var(--fixed-white);
                                    font-weight: bold;
                                    padding: var(--space-md) var(--space-lg);
                                    pointer-events: auto;

                                    @include breakpoint(md) {
                                        font-size: 1.25em;
                                        // width: 223px;
                                        // height: 69px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .allArticles {
        .container {
            .titleArticles {
                font-size: 1.25em;
                font-weight: 600;
                color: var(--color-big-title);
                margin: 7.0625em 0 19px;

                @include breakpoint(md) {
                    font-size: 2.5em;
                    margin: var(--space-lg) 0 0;
                }
            }
        }

        .cards-article {
            display: grid;
            justify-content: space-between;
            grid-template-columns: repeat(1, 1fr);

            @include breakpoint(md) {
                margin: var(--space-md) 0;
                grid-template-columns: repeat(3, 1fr);
                gap: var(--space-md);
                padding: 0;
            }

            .card-news {
                position: relative;
                margin-bottom: var(--space-lg);

                @include breakpoint(md) {
                    margin-bottom: var(--space-xxl);
                }

                .item_news {
                    .actu-content {
                        position: relative;
                        padding-bottom: 118%;

                        .zoomEffect {

                            position: absolute;
                            width: 100%;
                            height: 100%;
                            overflow: hidden;

                            .img_article {
                                height: 100%;

                                img {
                                    transition: transform .2s ease;
                                    height: 100%;
                                    width: 100%;
                                    object-fit: cover;
                                }
                            }
                        }

                        .description {
                            width: 100%;
                            height: fit-content;
                            padding: var(--space-md);
                            color: var(--fixed-white);
                            position: absolute;
                            background-color: var(--fixed-white-90);
                            bottom: 0;
                            box-sizing: border-box;
                            // min-height: 30%;

                            .plus_icon {
                                display: none;

                                @include breakpoint(md) {
                                    display: block;
                                    position: absolute;
                                    width: fit-content;
                                    transform: translateY(calc(-1 * (var(--space-md) + 50%)));
                                    right: 42px;
                                }
                            }

                            .container {
                                font-size: 12px;
                                color: var(--color-description);
                                font-weight: bold;
                                display: flex;
                                flex-direction: column;
                                justify-content: space-between;
                                height: 100%;
                                box-sizing: border-box;

                                @include breakpoint(md) {}

                                .category_item {
                                    font-size: 12px;
                                    font-weight: bold;
                                    color: var(--color-description);

                                    @include breakpoint(md) {
                                        font-size: 1.875em;
                                    }
                                }

                                .description_actu {
                                    .titre {
                                        font-size: 18px;
                                        font-weight: 500;
                                        color: var(--color-description);
                                        margin: 14px 0 38px;

                                        @include breakpoint(md) {
                                            font-size: 1.5625em;
                                        }
                                    }
                                }

                                .infos {
                                    display: flex;
                                    color: var(--color-description);
                                    font-weight: 200;
                                    font-size: 10px;
                                    margin-bottom: 19px;

                                    @include breakpoint(md) {
                                        margin-bottom: 0;
                                        font-size: 1.125em;
                                    }

                                    &>legend {
                                        margin: 0 4px;
                                    }
                                }

                                button {
                                    font-size: 12px;
                                    background-color: var(--primary);
                                    color: var(--fixed-white);
                                    font-weight: bold;
                                    padding: var(--space-md) var(--space-lg);
                                    pointer-events: auto;
                                    width: fit-content;

                                    @include breakpoint(md) {
                                        display: none;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

}